import { useState } from 'react'
import PropTypes from 'prop-types'
// import Carousel from 'react-material-ui-carousel'

// Material-UI imports
import { Button, IconButton, Modal, Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

// Imports from own project
import styles from './WhatIsNewModalContainer.module.scss'
import { LogoLightIcon, Heat, Heatmap } from 'icons'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
// import Carousel from 'react-material-ui-carousel'

const WhatIsNewModal = props => {
  const { hideModal, translate, onConfirmNews } = props

  const [open] = useState(true)
  const [showCarousel, setShowCarousel] = useState(false)

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const ConfirmCloseModal = props => {
    const { hideModal, translate, contentText, handleConfirmClick } = props

    const StyledPaper = withStyles({
      root: {
        maxHeight: '100% !important',
        'overflow-y': 'visible',
        border: '2px solid #dddddd',
        verticalAlign: 'center !important',
        borderRadius: '32px',
        backgroundColor: '#ffffff'
      }
    })(props => <Paper {...props} />)

    return (
      <Modal
        onClose={hideModal}
        open={open}
        fullWidth
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
        scroll='body'
        BackdropProps={{
          classes: {
            root: styles.closeBackdrop
          }
        }}
        PaperComponent={StyledPaper}
      >
        <div className={styles.centerModal}>
          <div className={styles.closeModalContainer}>
            <div className={styles.closeModalContainerContent}>
              <div className={styles.closeModalContainerContentText}>{contentText}</div>
              <div className={styles.closeModalContainerButtonWrapper}>
                <Button
                  disableRipple
                  onClick={handleConfirmClick}
                  className={styles.understoodButton}
                >
                  {translate('whatIsNew.understood')}
                </Button>
                <Button
                  onClick={hideModal}
                  className={styles.lastPageExitButton}
                >
                  {translate('whatIsNew.exit')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  // const Item = props => {
  //   const { item } = props
  //   return (
  //     <Paper className={styles.pageWrapper}>
  //       <div className={styles.carouselTitleWrapper}>
  //         {item.name && <h1 className={styles.carouselTitle}>{item.name}</h1>}
  //         <IconButton
  //           onClick={() => setShowConfirmationModal(true)}
  //           className={styles.titleIconClose}
  //         >
  //           <CloseIcon className={styles.iconClose} />
  //         </IconButton>
  //       </div>
  //       {item.imageText && <div className={styles.imageTextWrapper}>{item.imageText}</div>}
  //       {item.image && (
  //         <div className={styles.imageWrapper}>
  //           <img
  //             src={item.image}
  //             className={styles.carouselImage}
  //             alt='carouselImage'
  //           />
  //         </div>
  //       )}
  //       {item.imageMobile && (
  //         <div className={styles.imageMobileWrapper}>
  //           <img
  //             src={item.imageMobile}
  //             className={styles.carouselImage}
  //             alt='carouselImage'
  //           />
  //         </div>
  //       )}
  //       {item.description && <div className={styles.descriptionWrapper}>{item.description}</div>}
  //       {item.logo && (
  //         <div className={styles.titleWrapper}>
  //           <img
  //             className={styles.logoImage}
  //             src={LogoLightIcon}
  //             alt=''
  //           />
  //         </div>
  //       )}
  //       {item.lastMessage && <div className={styles.lastMessageWrapper}>{item.lastMessage}</div>}
  //       {item.last && (
  //         <div className={styles.buttonWrapper}>
  //           <div>
  //             <Button
  //               className={styles.understoodButton}
  //               onClick={handleExplore}
  //             >
  //               {translate('whatIsNew.explore')}
  //             </Button>
  //           </div>
  //         </div>
  //       )}
  //     </Paper>
  //   )
  // }

  const handleKnowMore = () => {
    setShowCarousel(true)
  }

  // const handleExplore = () => {
  //   hideModal()
  //   onExplore()
  // }

  const handleRemindMeLater = () => {
    hideModal()
  }

  const handleShowNoMore = () => {
    onConfirmNews()
    hideModal()
  }

  const MainPage = () => {
    return (
      <div className={styles.mainPageWrapper}>
        <IconButton
          onClick={() => setShowConfirmationModal(true)}
          className={styles.titleIconClose}
        >
          <CloseIcon className={styles.iconClose} />
        </IconButton>
        <div className={styles.iconWrapper}>
          <img
            className={styles.logoImage}
            src={LogoLightIcon}
            alt=''
          />
        </div>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>{translate('whatIsNew.mainPageTitle')}</h1>
          <img
            className={styles.heatLogoImage}
            src={Heat}
            alt=''
          />
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.mainPageDescription}>
            <Typography className={styles.mainPageDescriptionText}>
              {translate('whatIsNew.mainPageDescription')}
            </Typography>
          </div>
        </div>
        <div>
          <Button
            className={styles.knowMoreButton}
            onClick={handleKnowMore}
          >
            {translate('whatIsNew.knowMore')}
          </Button>
        </div>
        <div>
          <Button
            className={styles.exitButton}
            onClick={handleRemindMeLater}
          >
            {translate('whatIsNew.remindMeLater')}
          </Button>
        </div>
      </div>
    )
  }

  const ContentPage = () => {
    return (
      <div className={styles.contentPageWrapper}>
        <IconButton
          onClick={() => setShowConfirmationModal(true)}
          className={styles.titleIconClose}
        >
          <CloseIcon className={styles.iconClose} />
        </IconButton>
        <div className={styles.contentDescriptionTitle}>
          <Typography className={styles.contentPageDescriptionTitleText}>
            {translate('whatIsNew.contentDescriptionTitle')}
          </Typography>
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.contentPageImageWrapper}>
            <img
              src={Heatmap}
              alt='contentPageImage'
              className={styles.contentPageImage}
            />
          </div>
        </div>
        <div className={styles.contentDescription}>
          <Typography className={styles.contentPageDescriptionText}>
            {translate('whatIsNew.contentDescription')}
          </Typography>
        </div>
        {/* <div>
          <Button
            className={styles.exploreButton}
            onClick={handleExplore}
          >
            {translate('whatIsNew.explore')}
          </Button>
        </div> */}
      </div>
    )
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.centerModal}>
          <div className={styles.modalContainer}>
            {!showCarousel && <MainPage />}
            {showCarousel && <ContentPage />}
            {showConfirmationModal && (
              <ConfirmCloseModal
                translate={translate}
                handleConfirmClick={handleShowNoMore}
                contentText={translate('whatIsNew.remindLater')}
                hideModal={hideModal}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

WhatIsNewModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

export default WhatIsNewModal
