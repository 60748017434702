import { useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { Modal } from '@material-ui/core'
import styles from './DownloadKeysContainer.module.scss'
import { showSnackbar } from 'common/actions/snackbar'
import { connect } from 'react-redux'

import DownloadKeysForm from '../components/DownloadKeysForm'
import { downloadApiKeysCsv } from 'utils/downloadApiKeyCsv'

const DownloadKeysContainer = props => {
  const [open] = useState(true)

  const { hideModal, user, systems, showSnackbar } = props
  const { isPremium } = user.toJS()
  const apiKeySystems = systems.filter(
    system => system.apiKey !== undefined && system.apiKey !== '' && system.apiKey !== null
  )

  const handleSubmit = data => {
    downloadApiKeysCsv(data.fileName, data.downloadDevices)
    showSnackbar('snackbar.downloadKeysSuccess')
    hideModal()
  }

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <DownloadKeysForm
          {...props}
          isPremium={isPremium}
          onSubmit={handleSubmit}
          systems={apiKeySystems}
          showSnackbar={showSnackbar}
        />
      </div>
    </Modal>
  )
}

DownloadKeysContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}
const mapStateToProps = state => ({})

const mapActionsToProps = {
  showSnackbar
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(DownloadKeysContainer))
