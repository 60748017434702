import { StylesProvider } from '@material-ui/styles'
import styles from './SingleKeyCreated.module.scss'

export const SingleKeyCreated = ({ translate, hideModal, system, handleSubmit }) => {
  const onClickDownload = () => {
    handleSubmit('API Info ' + system.name, [system])
  }
  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <span className={styles.header}>{translate('integrationsPage.apiKeyCreated')}</span>
        <div className={styles.modalContent}>
          <div className={styles.modalTextLine}>
            <span className={styles.textAttribute}>Device name: </span>
            <span>{system.name}</span>
          </div>
          <div className={styles.modalTextLine}>
            <span className={styles.textAttribute}>API key: </span>
            <span>{system.apiKey}</span>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <button
            className={styles.downloadButton}
            onClick={() => onClickDownload()}
          >
            {translate('downloadFile').toUpperCase()}
          </button>
          <button
            className={styles.closeButton}
            onClick={hideModal}
          >
            {translate('close').toUpperCase()}
          </button>
        </div>
      </div>
    </StylesProvider>
  )
}
