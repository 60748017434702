import { useEffect, useState } from 'react'

const getWindowSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
  isMobile: window.innerWidth < 768
})

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize)

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowSize
}
