// ---------------- External imports ------------------ //
import { useState } from 'react'
import PropTypes from 'prop-types'
import FileSaver from 'file-saver'
import { withLocalize } from 'react-localize-redux'

// ---------------- Material-UI imports ------------------ //
import { Modal } from '@material-ui/core'

// ---------------- Internal imports ------------------ //
import DownloadSettingsForm from '../components/DownloadSettingsForm'
import { requestFile } from 'utils/request'
import { getAuthStoreData } from 'utils/authStore'
import styles from './DownloadSettingsContainer.module.scss'

const DownloadSettingsContainer = props => {
  const [open] = useState(true)

  const { hideModal, user } = props
  const { isPremium, _id, collaborativeInformation } = user.toJS()
  const [submitError, setSubmitError] = useState(false)

  const isManager =
    collaborativeInformation?.length > 0 && collaborativeInformation[0]?.role === 'Manager'

  const handleSubmit = async data => {
    if (props.isSubmitting) return

    const systemId = props.system._id
    const startDate = new Date(data.startDate.toString())
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)

    const endDate = new Date(data.endDate.toString())
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)

    const submitData = {
      params: data.params,
      fileType: data.fileType,
      micaname: props.system.name,
      order: Number(data.order),
      interval: data.interval,
      base: props.system.baseStationLocation,
      tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone,
      CustomerId: _id
    }
    if (!data.weather) delete submitData.base
    if (data.fileType === 'xlsx') {
      try {
        const authStoreData = getAuthStoreData()
        await requestFile(
          `systems/` + systemId + `/` + startDate.toISOString() + `/` + endDate.toISOString(),
          {
            method: 'POST',
            data: {
              ...submitData
            },
            headers: {
              accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'content-type': 'application/json',
              Authorization: authStoreData
            }
          }
        ).then(async response => {
          if (response === undefined) {
            setSubmitError(true)
          } else if (response === 413) {
            setSubmitError(true)
          } else {
            const contentType = response.headers.get('content-type')
            if (
              contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
              const blob = await response.blob().then(blob => blob)
              const fileName = response.headers.get('filename')
              FileSaver.saveAs(blob, fileName)
              hideModal()
            } else if (contentType === 'text/plain') {
              const url = await response.text()
              const alink = document.createElement('a')
              alink.href = url
              alink.click()
              hideModal()
            } else {
              setSubmitError(true)
            }
          }
        })
      } catch (error) {
        setSubmitError(true)
      }
    } else {
      try {
        await requestFile(
          `systems/` + systemId + `/` + startDate.toISOString() + `/` + endDate.toISOString(),
          {
            method: 'POST',
            data: {
              ...submitData
            }
          }
        ).then(async response => {
          if (response === undefined) {
            setSubmitError(true)
          } else if (response === 413) {
            setSubmitError(true)
          } else {
            const contentType = response.headers.get('content-type')
            if (contentType === 'text/csv') {
              const blob = await response.blob().then(blob => blob)
              const fileName = response.headers.get('filename')
              FileSaver.saveAs(blob, fileName)
              hideModal()
            } else if (contentType === 'text/plain') {
              const url = await response.text()
              const alink = document.createElement('a')
              alink.href = url
              alink.click()
              hideModal()
            } else {
              setSubmitError(true)
            }
          }
        })
      } catch (error) {
        setSubmitError(true)
      }
    }
  }

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <DownloadSettingsForm
          {...props}
          isPremium={isPremium}
          isManager={isManager}
          onSubmit={handleSubmit}
          submitError={submitError}
          setSubmitError={setSubmitError}
        />
      </div>
    </Modal>
  )
}

DownloadSettingsContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}

export default withLocalize(DownloadSettingsContainer)
