import cx from 'classnames'

import colors from 'styles/_variables.module.scss'
import * as DataUtils from 'utils/data'
import DataTypes from 'constants/DataTypes'
import DataLimits from 'constants/DataLimits'
import MeasurementUnits from 'constants/MeasurementUnits'
import styles from './CircleDetails.module.scss'
import { ReactComponent as PreheatingIcon } from 'icons/preheating.svg'
import { getCompleteMeasurementUnits } from 'utils/dataUnitsDefault'

const CircleDetails = ({ item, translate, dataCount, user, isModelEducation, oldO3Units }) => {
  const measurementUnits = getCompleteMeasurementUnits(user.measurementUnits)
  let value =
    item.measurements.length > 0 && typeof item.measurements[0].value === 'number'
      ? DataUtils.formatData(
          item.measurements[0].value,
          item.type,
          measurementUnits,
          oldO3Units
        )?.toFixed(DataUtils.getFixValue(item.type))
      : 'p'

  const getIndicatorColor = type => {
    let state, color

    if (value !== 'p') {
      switch (type) {
        case DataTypes.TEMPERATURE:
          if (
            value > DataUtils.formatData(DataLimits.TEMPERATURE_LOW, type, measurementUnits) &&
            value < DataUtils.formatData(DataLimits.TEMPERATURE_HIGH, type, measurementUnits)
          ) {
            state = 'good'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.RADIANT_TEMPERATURE:
          if (
            value >
              DataUtils.formatData(DataLimits.RADIANT_TEMPERATURE_LOW, type, measurementUnits) &&
            value <
              DataUtils.formatData(DataLimits.RADIANT_TEMPERATURE_HIGH, type, measurementUnits)
          ) {
            state = 'good'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.HUMIDITY:
          if (value > DataLimits.HUMIDITY_LOW_YELLOW && value < DataLimits.HUMIDITY_HIGH_YELLOW) {
            state = 'good'
          } else if (value < DataLimits.HUMIDITY_LOW_RED || value > DataLimits.HUMIDITY_HIGH_RED) {
            state = 'bad'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.CO2: {
          const lowCo2Limit = isModelEducation ? DataLimits.CO2_LOW_EDUCATION : DataLimits.CO2_LOW
          const highCo2Limit = isModelEducation
            ? DataLimits.CO2_HIGH_EDUCATION
            : DataLimits.CO2_HIGH
          if (value < lowCo2Limit) {
            state = 'good'
          } else if (value > highCo2Limit) {
            state = 'bad'
          } else {
            state = 'medium'
          }
          break
        }
        case DataTypes.PM25:
          if (value < DataLimits.PM25_LOW) {
            state = 'good'
          } else if (value > DataLimits.PM25_HIGH) {
            state = 'bad'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.PM1:
          if (value < DataLimits.PM1_LOW) {
            state = 'good'
          } else if (value > DataLimits.PM1_HIGH) {
            state = 'bad'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.PM4:
          if (value < DataLimits.PM4_LOW) {
            state = 'good'
          } else if (value > DataLimits.PM4_HIGH) {
            state = 'bad'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.PM10:
          if (value < DataLimits.PM10_LOW) {
            state = 'good'
          } else if (value > DataLimits.PM10_HIGH) {
            state = 'bad'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.FORMALDEHYDE:
          if (value < DataUtils.formatData(DataLimits.FORMALDEHYDE_LOW, type, measurementUnits)) {
            state = 'good'
          } else if (
            value > DataUtils.formatData(DataLimits.FORMALDEHYDE_HIGH, type, measurementUnits)
          ) {
            state = 'bad'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.VOCS:
          if (value < DataUtils.formatData(DataLimits.VOCS_LOW, type, measurementUnits)) {
            state = 'good'
          } else if (value > DataUtils.formatData(DataLimits.VOCS_HIGH, type, measurementUnits)) {
            state = 'bad'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.VOCS_INDEX:
          if (value < DataUtils.formatData(DataLimits.VOCS_INDEX_LOW, type, measurementUnits)) {
            state = 'good'
          } else if (
            value > DataUtils.formatData(DataLimits.VOCS_INDEX_HIGH, type, measurementUnits)
          ) {
            state = 'bad'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.CO:
          if (value < DataUtils.formatData(DataLimits.CO_LOW, type, measurementUnits)) {
            state = 'good'
          } else if (value > DataUtils.formatData(DataLimits.CO_HIGH, type, measurementUnits)) {
            state = 'bad'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.O3:
          if (value < DataUtils.formatData(DataLimits.O3_LOW, type, measurementUnits)) {
            state = 'good'
          } else if (value > DataUtils.formatData(DataLimits.O3_HIGH, type, measurementUnits)) {
            state = 'bad'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.RADON:
          if (value < DataLimits.RADON_LOW) {
            state = 'good'
          } else if (value > DataLimits.RADON_HIGH) {
            state = 'bad'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.NO2:
          if (value < DataLimits.NO2_LOW) {
            state = 'good'
          } else if (value > DataLimits.NO2_HIGH) {
            state = 'bad'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.NOX:
          if (value < DataLimits.NOX_LOW) {
            state = 'good'
          } else if (value > DataLimits.NOX_HIGH) {
            state = 'bad'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.NOISE:
          if (value < DataLimits.NOISE_LOW) {
            state = 'good'
          } else if (value > DataLimits.NOISE_HIGH) {
            state = 'bad'
          } else {
            state = 'medium'
          }
          break
        case DataTypes.LIGHT:
          if (value < DataLimits.LIGHT_HIGH_YELLOW && value > DataLimits.LIGHT_LOW_YELLOW) {
            state = 'good'
          } else {
            state = 'medium'
          }
          break
        default:
          break
      }
    } else {
      state = 'preheating'
    }
    switch (state) {
      case 'good':
        color = colors.colorSuccess
        break
      case 'medium':
        color = colors.colorWarning
        break
      case 'bad':
        color = colors.colorError
        break
      case 'preheating':
        color = colors.colorPreheating
        break
      default:
        break
    }
    return color
  }

  const getCircleStyles = () => {
    if (dataCount <= 4) {
      return styles.bigCircle
    } else if (dataCount <= 8) {
      return styles.mediumCircle
    } else if (dataCount <= 10) {
      return styles.smallCircle
    } else {
      return styles.xsmallCircle
    }
  }
  const circleContainer = () => {
    if (dataCount <= 4) {
      return styles.circleContiner2
    } else if (dataCount <= 6) {
      return styles.circleContiner
    } else if (dataCount <= 8) {
      return styles.circleContiner4
    } else if (dataCount <= 10) {
      return styles.circleContiner8
    } else {
      return styles.circleContiner10
    }
  }

  const getCircleValueStyles = () => {
    if (dataCount > 9) {
      return styles.circleValueSmall
    } else if (dataCount > 4 && dataCount < 9) {
      return styles.circleValueMedium
    } else {
      return styles.circleValue
    }
  }

  const getCircleUnitStyles = () => {
    if (dataCount > 9) {
      return styles.unitValueSmall
    } else if (dataCount > 4 && dataCount < 9) {
      return styles.unitValueMedium
    } else {
      return styles.unitValue
    }
  }

  const getParameterTextStyle = () => {
    if (dataCount > 10) {
      return styles.parameterTextSmall
    } else if (dataCount > 8) {
      return styles.parameterTextMicaNoIndicator
    } else if (dataCount > 4) {
      return styles.parameterTextMicaNoIndicatorMedium
    } else {
      return styles.parameterText
    }
  }

  const getUnit = () => {
    if (
      item.type === DataTypes.VOCS_INDEX &&
      (measurementUnits[item.type] === MeasurementUnits.VOCS_INDEX || !measurementUnits[item.type])
    ) {
      return 'INDEX'
    }
    if (
      item.type === DataTypes.O3 &&
      oldO3Units &&
      (measurementUnits[item.type] === MeasurementUnits.O3_UG || !measurementUnits[item.type])
    ) {
      return DataUtils.getDataUnit(item.type, MeasurementUnits.O3_UG)
    }

    return DataUtils.getDataUnit(item.type, measurementUnits[item.type])
  }

  return (
    <div className={circleContainer()}>
      <div className={styles.centred}>
        {value !== 'p' ? (
          <div
            className={cx('circle', styles.circleBorder)}
            style={{ borderColor: getIndicatorColor(item.type) }}
          >
            <div className={getCircleStyles()}>
              <div>
                <h4 className={getCircleValueStyles()}>{value}</h4>
                <p className={getCircleUnitStyles()}>{getUnit()}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.preheatingIconContainer}>
            <div className={getCircleStyles()}>
              <div className={styles.preheatingIcon}>
                <PreheatingIcon />
              </div>
            </div>
          </div>
        )}

        <p className={getParameterTextStyle()}>{translate(item.type)}</p>
      </div>
    </div>
  )
}

export default CircleDetails
