import { createReducer } from 'utils'
import { fromJS } from 'immutable'

import { constants } from 'common/actions/systems'
import { RESET_ERROR } from 'common/actions/error'

export const initialState = fromJS({
  isSubmitting: false,
  error: null
})

export default createReducer(initialState, {
  [RESET_ERROR]: state => state.set('error', null),

  [constants.CREATE_API_KEY_REQUEST.START]: state => state.set('isSubmitting', true),
  [constants.CREATE_API_KEY_REQUEST.SUCCESS]: () => initialState,
  [constants.CREATE_API_KEY_REQUEST.FAILURE]: (state, { payload: error }) => {
    return state.set('isSubmitting', false).set('error', error)
  }
})
