import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, browserHistory } from 'react-router'
import { getActiveLanguage, getTranslate, getLanguages } from 'react-localize-redux'

import { setActiveLanguage } from 'common/actions/localize'
import { getUser, getUserLang } from 'common/selectors/user'

import cx from 'classnames'
import styles from './AuxHeader.module.scss'
import { withStyles, StylesProvider } from '@material-ui/core/styles'

import * as Routes from 'constants/Routes'

import { AppBar, Toolbar, MenuItem, Menu, Fade, Button, Icon, SvgIcon } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import MenuIcon from '@material-ui/icons/Menu'
import Typography from '@material-ui/core/Typography'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'

import { InbiotLogo } from 'icons'
import { ReactComponent as ManageAccountsIcon } from 'icons/manage_accounts.svg'
import { ReactComponent as visLogo } from 'icons/visualisation.svg'
import { ReactComponent as HelpLogo } from 'icons/help.svg'
import { ReactComponent as ProfileLogo } from 'icons/profile.svg'
import { ReactComponent as IntegrationsIcon } from 'icons/integrations.svg'

const AuxHeader = props => {
  const collaborativeInformation = props.user?.toJS().collaborativeInformation
  const [helpAnchorEl, setHelpAnchorEl] = useState(null)
  const [userAnchorEl, setUserAnchorEl] = useState(null)

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

  const isManager =
    collaborativeInformation?.length > 0 && collaborativeInformation[0]?.role === 'Manager'

  const isViewer =
    collaborativeInformation?.length > 0 && collaborativeInformation[0]?.role === 'Viewer'

  const redirect = route => {
    browserHistory.push(route)
  }

  const openMobileMenu = () => {
    if (!isMobileMenuOpen) {
      setMobileMenuOpen(true)
    } else {
      setMobileMenuOpen(false)
    }
  }

  const closeMobileMenu = () => {
    setMobileMenuOpen(false)
  }

  const handleMenuHelpOpen = event => {
    window.open('https://docs.inbiot.es', '_blank')
  }

  const handleMenuUserOpen = event => {
    if (userAnchorEl) {
      setUserAnchorEl(null)
    } else {
      setUserAnchorEl(event.currentTarget)
    }
  }

  const handleHelpClick = option => {
    switch (option) {
      case 'use-info':
        window.open('https://docs.inbiot.es', '_blank')
        setHelpAnchorEl(null)
        break
      case 'faqs':
        window.open('https://docs.inbiot.es', '_blank')
        setHelpAnchorEl(null)
        break
      case 'contact':
        window.open('https://docs.inbiot.es', '_blank')
        setHelpAnchorEl(null)
        break
      default:
        break
    }
  }
  const isPremium = true
  const handleUserClick = option => {
    switch (option) {
      case 'edit-profile':
        setUserAnchorEl(false)
        redirect(Routes.PROFILE)
        break
      case 'visualization':
        setUserAnchorEl(false)
        redirect(Routes.VISUALISATION)
        break
      case 'logout':
        setUserAnchorEl(false)
        redirect(Routes.LOGOUT)
        break
      case 'integrations':
        setUserAnchorEl(false)
        redirect(Routes.INTEGRATIONS)
        break
      default:
        break
    }
  }

  const helpDropDown = () => {
    const StyledMenu = withStyles(styles.menuHelpPaper)(props => (
      <Menu
        {...props}
        classes={{ paper: styles.menuHelpPaper }}
      />
    ))

    return (
      <div>
        <Button
          disableRipple
          aria-controls='help-menu'
          aria-haspopup='true'
          onClick={handleMenuHelpOpen}
          classes={{ root: { textTransform: 'none' } }}
          className={cx(styles.navItem, styles.buttonLanguages)}
        >
          <HelpLogo className={styles.navHelpItemLogo} />
          <Typography variant='subtitle1'>{translate('help')}</Typography>
        </Button>
        <StyledMenu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          id='help-menu'
          anchorEl={helpAnchorEl}
          keepMounted
          open={Boolean(helpAnchorEl)}
          onClose={() => setHelpAnchorEl(null)}
          TransitionComponent={Fade}
        >
          <MenuItem
            key='use-info'
            onClick={() => handleHelpClick('use-info')}
            className={styles.menuItem}
          >
            <Typography>{translate('helpOptions.howMyInbiotWorks')}</Typography>
          </MenuItem>

          <MenuItem
            key='faqs'
            onClick={() => handleHelpClick('faqs')}
            className={styles.menuItem}
          >
            <Typography>{translate('faqs')}</Typography>
          </MenuItem>

          <MenuItem
            key='contact'
            onClick={() => handleHelpClick('contact')}
            className={styles.menuItem}
          >
            <Typography>{translate('helpOptions.contactUs')}</Typography>
          </MenuItem>
        </StyledMenu>
      </div>
    )
  }

  const userDropDown = () => {
    const StyledMenu = withStyles(styles.menuUserPaper)(props => (
      <Menu
        {...props}
        classes={{ paper: styles.menuUserPaper }}
      />
    ))

    return (
      <div>
        <Button
          disableRipple
          aria-controls='user-menu'
          aria-haspopup='true'
          onClick={handleMenuUserOpen}
          classes={{ root: { textTransform: 'none' } }}
          className={cx(styles.navItem, styles.buttonLanguages)}
        >
          <ProfileLogo className={styles.navUserItemLogo} />
          <Typography variant='subtitle1'>{translate('user')}</Typography>
          {!userAnchorEl && <KeyboardArrowDownIcon />}
          {Boolean(userAnchorEl) && <KeyboardArrowUpIcon />}
        </Button>
        <StyledMenu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          id='user-menu'
          anchorEl={userAnchorEl}
          keepMounted
          open={Boolean(userAnchorEl)}
          onClose={() => setUserAnchorEl(null)}
          TransitionComponent={Fade}
        >
          <MenuItem
            key='edit-profile'
            onClick={() => handleUserClick('edit-profile')}
            className={styles.menuItem}
          >
            <SvgIcon
              className={styles.userOptionsLogo}
              component={ManageAccountsIcon}
              viewBox='0 0 24 24'
            />
            <Typography className={styles.userOptionsText}>
              {isPremium ? translate('Configuration') : translate('editProfile')}
            </Typography>
          </MenuItem>

          <MenuItem
            key='visualization'
            onClick={() => handleUserClick('visualization')}
            className={styles.menuItem}
          >
            <SvgIcon
              className={styles.userOptionsLogo}
              component={visLogo}
              viewBox='0 0 45 40'
            />
            <Typography className={styles.userOptionsText}>{translate('visualization')}</Typography>
          </MenuItem>
          {!(isManager || isViewer) && (
            <MenuItem
              key='integrations'
              onClick={() => handleUserClick('integrations')}
              className={styles.menuItem}
            >
              <SvgIcon
                className={styles.userOptionsLogo}
                component={IntegrationsIcon}
                viewBox='0 0 24 24'
                fillOpacity='1'
              />
              <Typography className={styles.userOptionsText}>
                {translate('integrations')}
              </Typography>
            </MenuItem>
          )}

          <MenuItem
            key='logout'
            onClick={() => handleUserClick('logout')}
            className={styles.menuItem}
          >
            <ExitToAppOutlinedIcon className={styles.userOptionsLogo} />
            <Typography className={styles.userOptionsText}>{translate('logout')}</Typography>
          </MenuItem>
        </StyledMenu>
      </div>
    )
  }

  const overlay = () => {
    return (
      isMobileMenuOpen && (
        <div
          className={styles.overlay}
          onClick={closeMobileMenu}
        />
      )
    )
  }

  const { isLoggedIn, translate } = props

  return (
    <StylesProvider injectFirst>
      <AppBar className={isLoggedIn ? styles.componentLoggedIn : styles.componentLoggedOut}>
        <Toolbar className={styles.content}>
          <div className={styles.logoWrapper}>
            <Icon>
              <img
                className={styles.logoImage}
                src={InbiotLogo}
                alt='inBiot Logo'
              />
            </Icon>
          </div>
          <div className={styles.auxHeaderContent}>
            <div className={styles.help}>{helpDropDown()}</div>

            {isLoggedIn && <div className={styles.user}>{userDropDown()}</div>}

            <div className={styles.mobileNav}>
              {helpDropDown()}

              {isLoggedIn && <MenuIcon onClick={openMobileMenu} />}

              <div className={styles.mobileMenu}>{overlay()}</div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </StylesProvider>
  )
}

AuxHeader.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: getUser(state),
  userLanguage: getUserLang(state),
  activeLanguage: getActiveLanguage(state.localize),
  languages: getLanguages(state.localize),
  translate: getTranslate(state.localize)
})

const mapActionsToProps = {
  setActiveLanguage
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(AuxHeader))
