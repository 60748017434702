import { useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { Modal } from '@material-ui/core'
import styles from './ApiKeysCreatedContainer.module.scss'
import { showSnackbar } from 'common/actions/snackbar'
import { connect } from 'react-redux'

import { SingleKeyCreated } from '../components/SingleKeyCreated/SingleKeyCreated'
import { MultipleKeysCreated } from '../components/MultipleKeysCreated/MultipleKeysCreated'
import { getSystemsRaw } from 'common/selectors/systems'
import { downloadApiKeysCsv } from 'utils/downloadApiKeyCsv'

const ApiKeysCreatedContainer = props => {
  const [open] = useState(true)

  const { hideModal, submitedData, systems, showSnackbar } = props

  let systemsJS = []
  systems.toArray().map(s => systemsJS.push(s.toJS()))

  const newApiSystems = systemsJS.filter(system => submitedData.includes(system._id))

  const handleSubmit = (downloadFileName, newApiSystems) => {
    downloadApiKeysCsv(downloadFileName, newApiSystems)
    showSnackbar('snackbar.downloadKeysSuccess')
    hideModal()
  }

  if (submitedData?.length > 1) {
    return (
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.content}>
          <MultipleKeysCreated
            {...props}
            newApiSystems={newApiSystems}
            submitedData={submitedData}
            hideModal={hideModal}
            handleSubmit={handleSubmit}
          />
        </div>
      </Modal>
    )
  } else {
    return (
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.content}>
          <SingleKeyCreated
            {...props}
            system={newApiSystems[0]}
            submitedData={submitedData}
            handleSubmit={handleSubmit}
            hideModal={hideModal}
          />
        </div>
      </Modal>
    )
  }
}

ApiKeysCreatedContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}
const mapStateToProps = state => ({
  systems: getSystemsRaw(state)
})

const mapActionsToProps = {
  showSnackbar
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(ApiKeysCreatedContainer))
