export const MAIN = '/'
export const LOGIN = '/login'
export const LOGOUT = '/logout'
export const SIGN_UP = '/sign-up'
export const SIGN_UP_COLLABORATOR = '/sign-up-collaborator'
export const CONFIRM_USER = '/confirm-user'
export const CONFIRM_RECIPIENT = '/confirm-recipient'
export const CONFIRM_COLLABORATOR = '/confirm-collaborator'
export const ADD_ALERT_RECIPIENT = '/add-alert-recipient'
export const CONFIRM_USER_INFO = '/confirm-user-info'
export const FORGOTTEN_PASSWORD = '/forgotten-password'
export const RECOVER_PASSWORD = '/recover-password'
export const DASHBOARD = '/dashboard'
export const CHANNEL = '/channel'
export const PROFILE = '/profile'
export const TERMS = '/terms'
export const PRIVACY = '/privacy'
export const COOKIES = '/cookies'
export const MANAGE_COOKIES = '/manage-cookies'
export const NOT_FOUND = '/not-found'
export const POLICY = '/policy'
export const FULLSCREEN = '/fullscreen'
export const HOME = '/home'
export const FULLSCREEN_LINK = '/fullscreen-link'
export const ALERTS = '/alerts'
export const REPORTS = '/smart-report'
export const CERTIFICATIONS = '/certifications'
export const VISUALISATION = '/visualisation'
export const CHECK_COLLABORATOR = '/check-collaborator'
export const INTEGRATIONS = '/integrations'
