import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import DataUnits from 'constants/DataUnits'
import DataTypes from 'constants/DataTypes'
import { getWeatherIcon } from 'utils/weatherIcons'
import styles from './WeatherInfo.module.scss'
import * as DataUtils from 'utils/data'
import { getCompleteMeasurementUnits } from 'utils/dataUnitsDefault'

const WeatherInfo = ({ system, weather, translate, user }) => {
  const measurementUnits = getCompleteMeasurementUnits(user.measurementUnits)

  if (weather.measurements.length > 0) {
    return (
      <div className='background_shadow  h-100'>
        <div className={styles.box}>
          <div>
            <div className='location'>
              <div className={styles.weatherLocation}>
                <LocationOnOutlinedIcon className={styles.locationIcon} />
                <div className='text'>
                  <span className={styles.responsiveAdress}>
                    {system.address ? system.address : weather.address}
                  </span>
                  <span className={styles.outdoorWeather}>
                    {translate('fullScreenPage.outdoorWeather')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.weatherIconWrapper}>
              <img src={getWeatherIcon(weather.measurements[0].weather[0].icon)} />
            </div>
          </div>
          <div>
            <div className={styles.paramContainer}>
              <h3 className={styles.temperatureValue}>
                <span>
                  {DataUtils.formatData(
                    weather.measurements[0].temp,
                    DataTypes.TEMPERATURE,
                    measurementUnits
                  )?.toFixed(0)}
                </span>
                {DataUtils.getDataUnit(DataTypes.TEMPERATURE, measurementUnits?.temperature)}
              </h3>
              <span className={styles.parameterTitle}>{translate('temperature')}</span>
            </div>
          </div>
          <div className={styles.paramContainer}>
            <h3 className={styles.temperatureValue}>
              <span>{weather.measurements[0].humidity}</span>
              <span style={{ paddingRight: '5px' }}>{DataUnits[DataTypes.HUMIDITY]}</span>
            </h3>
            <span className={styles.parameterTitle}>{translate('humidity')}</span>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default WeatherInfo
