import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'

import { StylesProvider } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import styles from './RegenerateKeyForm.module.scss'

const RegenerateKeyForm = props => {
  const { onSubmit, translate, isSubmitting } = props

  const onClickCancel = () => {
    props.hideModal()
  }

  const onClickDownload = () => {
    onSubmit()
  }

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>
          {translate('integrationsPage.regenerateKey')}
        </Typography>
        <div className={styles.modal}>
          <div className={styles.page}>
            <div className={styles.modalContent}>
              <div>
                <span>{translate('integrationsPage.regenerateKeyText')}</span>
              </div>
            </div>
            <div>
              <div className={styles.buttonWrapper}>
                <button
                  className={styles.buttonSiguiente}
                  disabled={isSubmitting}
                  onClick={onClickDownload}
                >
                  {translate('regenerate').toUpperCase()}
                </button>
                <button
                  className={styles.buttonCancelar}
                  disabled={isSubmitting}
                  onClick={onClickCancel}
                >
                  {translate('cancel')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StylesProvider>
  )
}

RegenerateKeyForm.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(RegenerateKeyForm)
