const TEMPERATURE_LOW = 19.0
const TEMPERATURE_HIGH = 27.0

const RADIANT_TEMPERATURE_LOW = 19.0
const RADIANT_TEMPERATURE_HIGH = 27.0

const HUMIDITY_LOW_RED = 25
const HUMIDITY_LOW_YELLOW = 35
const HUMIDITY_HIGH_YELLOW = 60
const HUMIDITY_HIGH_RED = 70

const CO2_LOW = 800
const CO2_HIGH = 1500
const CO2_LOW_EDUCATION = 700
const CO2_HIGH_EDUCATION = 900

const PM25_LOW = 12
const PM25_HIGH = 35

const PM1_LOW = 12
const PM1_HIGH = 35

const PM4_LOW = 12
const PM4_HIGH = 35

const PM10_LOW = 50
const PM10_HIGH = 100

const FORMALDEHYDE_LOW = 70
const FORMALDEHYDE_HIGH = 120

const VOCS_LOW = 220
const VOCS_HIGH = 660

const VOCS_INDEX_LOW = 200
const VOCS_INDEX_HIGH = 400

const CO_LOW = 9
const CO_HIGH = 25

const O3_LOW = 51
const O3_HIGH = 122

const RADON_LOW = 100
const RADON_HIGH = 300

const NOX_LOW = 150
const NOX_HIGH = 350

const NO2_LOW = 21
const NO2_HIGH = 105

const NOISE_LOW = 45
const NOISE_HIGH = 80

const LIGHT_LOW_YELLOW = 300
const LIGHT_HIGH_YELLOW = 1000

// ---- INDICATORS ------- //

const INDICATORS_RED = 40
const INDICATORS_YELLOW = 70

export default {
  TEMPERATURE_LOW,
  TEMPERATURE_HIGH,

  RADIANT_TEMPERATURE_LOW,
  RADIANT_TEMPERATURE_HIGH,

  HUMIDITY_LOW_RED,
  HUMIDITY_LOW_YELLOW,
  HUMIDITY_HIGH_YELLOW,
  HUMIDITY_HIGH_RED,

  CO2_LOW,
  CO2_HIGH,
  CO2_LOW_EDUCATION,
  CO2_HIGH_EDUCATION,

  PM1_LOW,
  PM1_HIGH,
  PM25_LOW,
  PM25_HIGH,
  PM4_LOW,
  PM4_HIGH,
  PM10_LOW,
  PM10_HIGH,

  FORMALDEHYDE_LOW,
  FORMALDEHYDE_HIGH,

  VOCS_LOW,
  VOCS_HIGH,
  VOCS_INDEX_LOW,
  VOCS_INDEX_HIGH,

  CO_LOW,
  CO_HIGH,

  O3_LOW,
  O3_HIGH,

  RADON_LOW,
  RADON_HIGH,

  NOX_LOW,
  NOX_HIGH,

  NO2_LOW,
  NO2_HIGH,

  NOISE_LOW,
  NOISE_HIGH,

  LIGHT_LOW_YELLOW,
  LIGHT_HIGH_YELLOW,

  INDICATORS_RED,
  INDICATORS_YELLOW
}
