import { useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import _ from 'lodash'

import { makeStyles, StylesProvider } from '@material-ui/core/styles'
import {
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
  ListItem,
  ListItemText,
  Collapse
} from '@material-ui/core'

import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { blue } from '@material-ui/core/colors'
import styles from './DownloadKeysForm.module.scss'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  }
}))

const DownloadKeysForm = props => {
  const { onSubmit, translate, systems, isSubmitting } = props

  const classes = useStyles()

  const { handleSubmit, register, errors, setError, clearErrors } = useForm()

  const [open, setOpen] = useState({})
  const [filterExpression, setFilterExpression] = useState('')
  const [downloadFileName, setDownloadFileName] = useState('')
  const [selectedDevices, setSelectedDevices] = useState([])
  const [isCheckAllDevices, setIsCheckAllDevices] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  const [checkAllDevicesIsClicked, setCheckDevicesAllIsClicked] = useState(false)

  const onClickCancel = () => {
    props.hideModal()
  }

  const checkDeviceSelected = () => {
    if (selectedDevices.length === 0) {
      setError('selectedDevices', { type: 'required' })
    }
  }

  const checkFileName = () => {
    if (downloadFileName.trim() === '') {
      setError('fileName', { type: 'required' })
    }
  }

  const goToNextPage = () => {
    checkDeviceSelected()
    if (!errors.selectedDevices) {
      setActiveTab(1)
    }
  }
  const handleDownloadFileNameChange = value => {
    setDownloadFileName(value)
    clearErrors('fileName')
  }
  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }

  const handleSelectAllDevices = event => {
    setIsCheckAllDevices(!isCheckAllDevices)
    setCheckDevicesAllIsClicked(true)
    if (isCheckAllDevices) {
      setSelectedDevices([])
    } else {
      const systemIds = []
      systems.map(s => {
        systemIds.push(s._id)
        return null
      })
      setSelectedDevices(systemIds)
      clearErrors('selectedDevices')
    }
  }

  const isIndeterminateDevices = () => {
    return (
      checkAllDevicesIsClicked &&
      selectedDevices.length > 0 &&
      selectedDevices.length !== systems.length
    )
  }

  const handleChangeDevice = event => {
    clearErrors('selectedDevices')
    const { checked, value } = event.target
    if (checked) {
      setSelectedDevices([...selectedDevices, value])
    } else {
      setSelectedDevices(selectedDevices.filter(item => item !== value))
    }
  }

  const handleFilterExpressionChange = event => {
    setFilterExpression(event.target.value)
  }

  const onClickDownload = () => {
    checkFileName()
    if (!errors.fileName) {
      if (selectedDevices.length !== 0) {
        const downloadDevices = systems.filter(s => selectedDevices.includes(s._id))
        const submitData = {
          downloadDevices,
          fileName: downloadFileName
        }
        onSubmit(submitData)
      }
    }
  }

  let systemsJS = []

  if (filterExpression !== '') {
    systemsJS = systems.filter(s => {
      return s.name.toLowerCase().indexOf(filterExpression.toLowerCase()) !== -1
    })
  } else {
    systemsJS = systems
  }
  const systemsType = _.groupBy(systemsJS, 'micaType')

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>
          {translate('integrationsPage.downloadKeysFile')}
        </Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <div className={styles.page}>
                <div className={styles.nav}>
                  <Typography className={cx(styles.subheaderDevice, styles.selected)}>
                    {'1. '}
                    {translate('integrationsPage.keys')}
                  </Typography>
                  <Typography className={styles.subheaderDevice}>
                    {'2. '}
                    {translate('file')}
                  </Typography>
                </div>
                <TextField
                  variant='outlined'
                  fullWidth
                  type='search'
                  onChange={handleFilterExpressionChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                  id='searchDevice'
                  name='searchDevice'
                  placeholder={translate('searchDevice')}
                  inputProps={{ maxLength: 32 }}
                  className={cx(classes.input, styles.input, {
                    [styles.hasError]: errors.alarmName
                  })}
                />
                <div className={styles.subInfoWrapper}>
                  <Typography className={styles.subInfo}>
                    {translate('downloadDataForm.selectDevices')}
                  </Typography>
                </div>
                <FormControlLabel
                  className={styles.selectAllCheckboxForm}
                  control={
                    <Checkbox
                      className={cx(styles.checkbox)}
                      classes={{ checked: styles.checkedBox }}
                      name='selectAllDevices'
                      id='selectAllDevices'
                      indeterminate={isIndeterminateDevices()}
                      onChange={handleSelectAllDevices}
                      checked={isCheckAllDevices}
                    />
                  }
                  label={translate('downloadDataForm.selectAll')}
                />
                <Paper
                  className={styles.devicesListPaper}
                  classes={{ root: classes.paper }}
                >
                  <List
                    disableTouchRipple
                    className={styles.devicesList}
                  >
                    <div className={styles.noGroupWrapper}>
                      {_.map(systemsType, function (systems, type) {
                        return (
                          <div className={styles.typeRowWrapper}>
                            <ListItem
                              button
                              disableTouchRipple
                              className={styles.typeListItem}
                            >
                              {!open[type] ? (
                                <ExpandLess
                                  onClick={() => handleClick(type)}
                                  className={styles.expandIcon}
                                />
                              ) : (
                                <ExpandMore
                                  onClick={() => handleClick(type)}
                                  className={styles.expandIcon}
                                />
                              )}
                              <ListItemText
                                primary={type}
                                className={styles.micaTypeItemText}
                              />
                            </ListItem>
                            <Collapse
                              in={!open[type]}
                              timeout='auto'
                              unmountOnExit
                            >
                              {systems.map((system, index) => {
                                return (
                                  <div
                                    key={system._id}
                                    className={styles.micaItem}
                                  >
                                    <FormControlLabel
                                      className={styles.checkboxForm}
                                      control={
                                        <Checkbox
                                          className={cx(styles.checkbox)}
                                          classes={{
                                            checked: styles.checkedBox
                                          }}
                                          checked={selectedDevices.includes(system._id)}
                                          onChange={handleChangeDevice}
                                          required
                                          outline='true'
                                          inputRef={register()}
                                          disableRipple
                                          name={`selectedDevices[]`}
                                          value={system._id}
                                        />
                                      }
                                      label={system.name}
                                    />
                                  </div>
                                )
                              })}
                            </Collapse>
                          </div>
                        )
                      })}
                    </div>
                  </List>
                </Paper>
                {errors.selectedDevices && (
                  <div className={styles.error}>
                    <small>{translate('validation.anyDeviceRequired')}</small>
                  </div>
                )}
              </div>
              <div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonSiguiente}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={() => goToNextPage()}
                  >
                    {translate('continue')}
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonCancelar}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
            <div
              className={styles.page}
              style={{ display: activeTab === 1 ? 'flex' : 'none' }}
            >
              <div className={styles.nav}>
                <Typography className={styles.subheaderDevice}>
                  {'1. '}
                  {translate('integrationsPage.keys')}
                </Typography>
                <Typography className={cx(styles.subheaderDevice, styles.selected)}>
                  {'2. '}
                  {translate('file')}
                </Typography>
              </div>
              <div className={styles.modalContent}>
                <div className={styles.fileNameInput}>
                  <input
                    type='text'
                    placeholder={translate('fileName')}
                    onChange={e => handleDownloadFileNameChange(e.target.value)}
                    value={downloadFileName}
                  />
                </div>
                <div className={styles.modalText}>
                  <span>{translate('integrationsPage.apiKeysDonwloadText')}</span>
                </div>
                {errors.fileName && (
                  <div className={styles.error}>
                    <small>{translate('validation.fileNameRequired')}</small>
                  </div>
                )}
              </div>
              <div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonSiguiente}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickDownload}
                  >
                    {translate('downloadFile')}
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonCancelar}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

DownloadKeysForm.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(DownloadKeysForm)
