import { useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { Modal } from '@material-ui/core'
import styles from './AvailableKeysContainer.module.scss'
import { connect } from 'react-redux'

import AvailableKeysForm from '../components/AvailableKeysForm'

const AvailableKeysContainer = props => {
  const [open] = useState(true)

  const { hideModal, apiLimitKeys } = props

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <AvailableKeysForm
          {...props}
          apiLimitKeys={apiLimitKeys}
          hideModal={hideModal}
        />
      </div>
    </Modal>
  )
}

AvailableKeysContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}
const mapStateToProps = state => ({})

const mapActionsToProps = {}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(AvailableKeysContainer))
