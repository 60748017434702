import { useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { Modal } from '@material-ui/core'
import styles from './DeleteKeyContainer.module.scss'
import { showSnackbar } from '../../../../common/actions/snackbar'
import { connect } from 'react-redux'
import { deleteApiKey } from '../../../../common/actions/systems'

import DeleteKeyForm from '../components/DeleteKeyForm'

const DeleteKeyContainer = props => {
  const [open] = useState(true)

  const { hideModal, system, user, deleteApiKey } = props
  const { isPremium } = user.toJS()

  const handleSubmit = () => {
    deleteApiKey(system._id)
    hideModal()
  }

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <DeleteKeyForm
          {...props}
          isPremium={isPremium}
          onSubmit={handleSubmit}
          system={system}
        />
      </div>
    </Modal>
  )
}

DeleteKeyContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}
const mapStateToProps = state => ({})

const mapActionsToProps = {
  deleteApiKey,
  showSnackbar
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(DeleteKeyContainer))
