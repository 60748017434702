import { LoadingIcon } from 'icons'
import styles from './LoadingComponent.module.scss'

export const LoadingComponent = () => {
  return (
    <div className={styles.loadingIconContainer}>
      <img src={LoadingIcon} />
    </div>
  )
}

export const LittleLoadingComponent = () => {
  return (
    <span className={styles.littleLoadingIconContainer}>
      <img src={LoadingIcon} />
    </span>
  )
}
