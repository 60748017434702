import request from 'utils/request'

export function getSystemById(id) {
  return request(`systems/${id}/last-measurement`, {
    method: 'GET'
  }).then(([body]) => body)
}

export function getSystemMeasurementsByTypeNew(data) {
  return request(`systems/mtype`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function getSystemHeatMapByTypeNew(data) {
  return request(`systems/heatmap`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function getSystemsHome(id) {
  return request('systems/byUserId', {
    method: 'POST',
    data: {
      id
    }
  }).then(([body]) => body)
}
export function getSystemsCollHome(data) {
  return request('systems/byUserId', {
    method: 'POST',
    data: {
      id: data.id,
      mainCustomerId: data.mainCustomerId,
      attachedDevices: data.attachedDevices
    }
  }).then(([body]) => body)
}

export function updateSystem(data) {
  return request('systems', {
    method: 'PUT',
    data
  }).then(([body]) => body)
}
export function updateSystemWeather(data) {
  return request('systems/weather', {
    method: 'PUT',
    data
  }).then(([body]) => body)
}

export function updateSystemLights(id, data) {
  return request(`systems/${id}/lights`, {
    method: 'PUT',
    data
  })
}

export function updateSystemCalibration(id, data) {
  return request(`systems/${id}/calibration`, {
    method: 'PUT',
    data
  })
}

export function updateMultipleSystemCalibration(data) {
  return request(`systems/calibration`, {
    method: 'PUT',
    data
  })
}

export function updateSystemPeriodicity(id, data) {
  return request(`systems/${id}/periodicity`, {
    method: 'PUT',
    data
  })
}

export function updateMultipleSystemLights(data) {
  return request(`systems/lights`, {
    method: 'PUT',
    data
  })
}

export function updateMultipleSystemPeriodicity(data) {
  return request(`systems/periodicity`, {
    method: 'PUT',
    data
  })
}

export function deleteDataFromUser(id) {
  return request(`systems/${id}/data`, {
    method: 'DELETE'
  })
}

export function createGroup(data) {
  return request(`systems/group`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function deleteGroup(data) {
  return request(`systems/group/delete`, {
    method: 'POST',
    data
  })
}

export function deleteGroupSystem(id) {
  return request(`systems/${id}/group`, {
    method: 'DELETE'
  })
}

export function getScreens(id) {
  return request(`systems/screens/${id}`, {
    method: 'GET'
  }).then(([body]) => body)
}

export function createScreen(data) {
  return request(`systems/screens`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function createApiKey(data) {
  return request(`systems/createApiKeys`, {
    method: 'PUT',
    data
  }).then(([body]) => body)
}

export function regenerateApiKey(deviceId) {
  return request(`systems/regenerateApiKey/${deviceId}`, {
    method: 'PUT'
  }).then(([body]) => body)
}

export function deleteApiKey(deviceId) {
  return request(`systems/deleteApiKey/${deviceId}`, {
    method: 'PUT'
  }).then(([body]) => body)
}

export const setApiKeyStatus = data => {
  return request(`systems/setApiKeyStatus`, {
    method: 'PUT',
    data
  }).then(([body]) => body)
}

export function updateScreen(id, data) {
  return request(`systems/screens/${id}`, {
    method: 'PUT',
    data
  }).then(([body]) => body)
}

export function deleteScreen(id) {
  return request(`systems/screens/${id}`, {
    method: 'DELETE'
  })
}

export function createAlarm(data) {
  return request(`systems/alarms`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function updateAlarm(id, data) {
  return request(`systems/alarms/${id}`, {
    method: 'PUT',
    data
  }).then(([body]) => body)
}

export function deleteAlarm(id) {
  return request(`systems/alarms/${id}`, {
    method: 'DELETE'
  })
}

export function getAlarms(id) {
  return request(`systems/alarms/${id}`, {
    method: 'GET'
  }).then(([body]) => body)
}

export function changeAlarmStatus(id, data) {
  return request(`systems/alarms/activate/${id}`, {
    method: 'PUT',
    data
  }).then(([body]) => body)
}

export function createInactivityAlarm(data) {
  return request(`systems/inactivityAlarms`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function updateInactivityAlarm(id, data) {
  return request(`systems/inactivityAlarms/${id}`, {
    method: 'PUT',
    data
  }).then(([body]) => body)
}

export function deleteInactivityAlarm(id) {
  return request(`systems/inactivityAlarms/${id}`, {
    method: 'DELETE'
  })
}

export function getInactivityAlarms(id) {
  return request(`systems/inactivityAlarms/${id}`, {
    method: 'GET'
  }).then(([body]) => body)
}

/* activateInactivityAlarms */
export function changeInactivityAlarmStatus(id, data) {
  return request(`systems/inactivityAlarms/activate/${id}`, {
    method: 'PUT',
    data
  }).then(([body]) => body)
}
