/* eslint-disable no-useless-escape */
// ---------------------- External imports ------------------------ //
import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withLocalize, Translate } from 'react-localize-redux'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import _ from 'lodash'

// ---------------------- Material-UI imports ------------------------ //
import { ArrowForward, ArrowBack } from '@material-ui/icons'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { withStyles, StylesProvider, makeStyles } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {
  IconButton,
  Fade,
  Menu,
  MenuItem,
  TextField,
  Input,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  Radio,
  InputAdornment,
  ListItem,
  Paper,
  List,
  ListItemText,
  Collapse,
  Switch
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { LoadingButton } from '@mui/lab'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
// ---------------------- Internal imports ------------------------ //
import DataTypes from 'constants/DataTypes'
import styles from './EditParameterAlertModalForm.module.scss'
import * as SystemUtils from 'utils/system'
import { getUser } from 'common/selectors/user'
import * as DataUtils from 'utils/data'
import DataLimits from 'constants/DataLimits'
import MeasurementUnits from 'constants/MeasurementUnits'
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { getCompleteMeasurementUnits } from 'utils/dataUnitsDefault'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  inp: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  switch: {
    '& .Mui-checked': {
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary': {
      color: 'rgba(255,255,255)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: 'rgba(0,155,229,1)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blue[600],
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-thumb': {
      color: blue[600]
    },

    '& .MuiSwitch-thumb': {
      transform: 'translateY(-3px)',
      width: '21px',
      height: '21px'
    },
    '& .MuiSwitch-track': {
      backgroundColor: 'rgba(33,33,33, 0.4)'
    },
    '& .MuiSwitch-switchBase': {
      color: '#fafafa',
      backgroundColor: 'transparent',
      outline: 'none'
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: 'rgba(0,155,229,1)',
      backgroundColor: 'transparent',
      outline: 'none'
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blue[600],
      color: blue[600]
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-thumb': {
      color: blue[600]
    },
    '& .MuiSwitch-switchBase.Mui-checked:hover': {
      backgroundColor: 'transparent',
      outline: 'none'
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  },
  MuiTimePicker: {
    styleOverrides: {
      root: {
        backgroundColor: blue[600]
      }
    }
  }
}))

const EditParameterAlertModalForm = props => {
  const {
    onSubmit,
    user,
    translate,
    isSubmitting,
    submitError,
    alarm,
    systems,
    addAlertRecipient,
    onDeleteAlertRecipient
  } = props
  const classes = useStyles()

  const checkedEmails = {}
  function handleTimeChange(newHours, newMinutes) {
    const modifiedDate = new Date()
    modifiedDate.setHours(newHours)
    modifiedDate.setMinutes(newMinutes)
    return modifiedDate
  }
  useEffect(() => {
    const emailsConfAux = []
    const emailsUnconfAux = []
    for (let i = 0; i < user.toJS().confirmedRecipients?.length; i++) {
      emailsConfAux[i] = {
        email: user.toJS().confirmedRecipients[i],
        confirmed: true
      }
    }
    for (let i = 0; i < user.toJS().unconfirmedRecipients?.length; i++) {
      emailsUnconfAux[i] = {
        email: user.toJS().unconfirmedRecipients[i],
        confirmed: false
      }
    }

    setSearched([...emailsConfAux, ...emailsUnconfAux])
  }, [user])
  const now = new Date()
  let offset = now.getTimezoneOffset()

  useEffect(() => {
    let firstStartTime
    let firstEndTime
    let secondStartTime
    let secondEndTime
    if (alarm?.alarmMailHours) {
      if (alarm?.alarmMailHours?.auxPeriod?.length === 2) {
        if (alarm.alarmMailHours.period1[0] === alarm.alarmMailHours.period1[1]) {
          firstStartTime = handleTimeChange(
            parseInt(alarm.alarmMailHours.auxPeriod[0].split(':')[0]),
            parseInt(alarm.alarmMailHours.auxPeriod[0].split(':')[1])
          )
          firstEndTime = handleTimeChange(
            parseInt(alarm.alarmMailHours.auxPeriod[1].split(':')[0]),
            parseInt(alarm.alarmMailHours.auxPeriod[1].split(':')[1])
          )
        } else {
          if (offset > 0) {
            firstStartTime = handleTimeChange(
              parseInt(alarm.alarmMailHours.period1[0].split(':')[0]),
              parseInt(alarm.alarmMailHours.period1[0].split(':')[1])
            )
            firstEndTime = handleTimeChange(
              parseInt(alarm.alarmMailHours.auxPeriod[1].split(':')[0]),
              parseInt(alarm.alarmMailHours.auxPeriod[1].split(':')[1])
            )
          } else {
            firstEndTime = handleTimeChange(
              parseInt(alarm.alarmMailHours.period1[1].split(':')[0]),
              parseInt(alarm.alarmMailHours.period1[1].split(':')[1])
            )
            firstStartTime = handleTimeChange(
              parseInt(alarm.alarmMailHours.auxPeriod[0].split(':')[0]),
              parseInt(alarm.alarmMailHours.auxPeriod[0].split(':')[1])
            )
          }
        }
        setFirstStartHour(new Date(firstStartTime.getTime() - offset * 60000))
        setFirstEndHour(new Date(firstEndTime.getTime() - offset * 60000))
      } else {
        firstStartTime = handleTimeChange(
          parseInt(alarm.alarmMailHours.period1[0].split(':')[0]),
          parseInt(alarm.alarmMailHours.period1[0].split(':')[1])
        )
        firstEndTime = handleTimeChange(
          parseInt(alarm.alarmMailHours.period1[1].split(':')[0]),
          parseInt(alarm.alarmMailHours.period1[1].split(':')[1])
        )
        setFirstStartHour(new Date(firstStartTime.getTime() - offset * 60000))
        setFirstEndHour(new Date(firstEndTime.getTime() - offset * 60000))
      }
      if (alarm?.alarmMailHours?.period2?.length === 2) {
        setSecondOccupationHoursChecked(true)
        if (alarm.alarmMailHours.period2[0] === alarm.alarmMailHours.period2[1]) {
          secondStartTime = handleTimeChange(
            parseInt(alarm.alarmMailHours.auxPeriod2[0].split(':')[0]),
            parseInt(alarm.alarmMailHours.auxPeriod2[0].split(':')[1])
          )
          secondEndTime = handleTimeChange(
            parseInt(alarm.alarmMailHours.auxPeriod2[1].split(':')[0]),
            parseInt(alarm.alarmMailHours.auxPeriod2[1].split(':')[1])
          )
        } else {
          if (alarm?.alarmMailHours?.auxPeriod2?.length === 2) {
            if (offset > 0) {
              secondStartTime = handleTimeChange(
                parseInt(alarm.alarmMailHours.period2[0].split(':')[0]),
                parseInt(alarm.alarmMailHours.period2[0].split(':')[1])
              )
              secondEndTime = handleTimeChange(
                parseInt(alarm.alarmMailHours.auxPeriod2[1].split(':')[0]),
                parseInt(alarm.alarmMailHours.auxPeriod2[1].split(':')[1])
              )
            } else {
              secondEndTime = handleTimeChange(
                parseInt(alarm.alarmMailHours.period2[1].split(':')[0]),
                parseInt(alarm.alarmMailHours.period2[1].split(':')[1])
              )
              secondStartTime = handleTimeChange(
                parseInt(alarm.alarmMailHours.auxPeriod2[0].split(':')[0]),
                parseInt(alarm.alarmMailHours.auxPeriod2[0].split(':')[1])
              )
            }
          } else {
            secondEndTime = handleTimeChange(
              parseInt(alarm.alarmMailHours.period2[1].split(':')[0]),
              parseInt(alarm.alarmMailHours.period2[1].split(':')[1])
            )
            secondStartTime = handleTimeChange(
              parseInt(alarm.alarmMailHours.period2[0].split(':')[0]),
              parseInt(alarm.alarmMailHours.period2[0].split(':')[1])
            )
          }
        }
        setSecondStartHour(new Date(secondStartTime.getTime() - offset * 60000))
        setSecondEndHour(new Date(secondEndTime.getTime() - offset * 60000))
      }
    }
  }, [alarm])
  const systemsByGroup = _.groupBy(systems, 'group')
  const measurementUnits = getCompleteMeasurementUnits(user.toJS().measurementUnits)
  const defaultDate = new Date()
  defaultDate.setHours(0)
  defaultDate.setMinutes(0)
  const [isCheckAllDevices, setIsCheckAllDevices] = useState(false)
  const [secondEndHour, setSecondEndHour] = useState(defaultDate)
  const [secondStartHour, setSecondStartHour] = useState(defaultDate)
  const [firstEndHour, setFirstEndHour] = useState(defaultDate)
  const [firstStartHour, setFirstStartHour] = useState(defaultDate)
  const [selectedTypes, setSelectedTypes] = useState([])
  const [defaultValuesState, setDefaultValuesState] = useState({})
  const [emails, setEmails] = useState(checkedEmails)
  const [searched, setSearched] = useState([])
  const [addEmail, setAddEmail] = useState('')
  const [openInfo, setOpenInfo] = useState({})
  const [openInfoE, setOpenInfoE] = useState({})
  const [activeTab, setActiveTab] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [level, setLevel] = useState(translate('selectLevel'))
  const [levelType, setLevelType] = useState('Personalized')
  const [byDefaultSelcetedDevices, setByDefaultSelcetedDevices] = useState([])
  const [infoTab, setInfoTab] = useState('')
  const [isCheckAllDay, setIsCheckAllDay] = useState(false)
  const [openPicker, setOpenPicker] = useState(false)
  const [isActive, setIsActive] = useState(0)
  const [typeCheckedBox, setTypeCheckedBox] = useState(alarm?.alarmMailHours?.days || {})
  const [secondOccupationHoursChecked, setSecondOccupationHoursChecked] = useState(false)
  const [alarmName, setAlarmName] = useState('')
  const [period1, setPeriod1] = useState([])
  const [period2, setPeriod2] = useState([])
  const [auxPeriod, setAuxPeriod] = useState([])
  const [auxPeriod2, setAuxPeriod2] = useState([])
  const [auxDays, setAuxDays] = useState()
  const [systemsGroupState, setSystemsGroupState] = useState(systemsByGroup)
  const [checkAllDevicesIsClicked, setCheckDevicesAllIsClicked] = useState(false)
  const [selectedDevices, setSelectedDevices] = useState([])
  const [open, setOpen] = useState({})
  const infoDivRef = useRef(null)
  const buttonRef = useRef(null)
  const infoTabRef = useRef(null)
  const buttonRefIcon = useRef(null)
  const handleClickOutside = event => {
    if (
      infoDivRef.current &&
      !infoDivRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setOpenInfoE(false)
    }
  }
  const handleClickOutsideDiv = event => {
    if (
      infoTabRef.current &&
      !infoTabRef.current.contains(event.target) &&
      buttonRefIcon.current &&
      !buttonRefIcon.current.contains(event.target)
    ) {
      setInfoTab('')
    }
  }

  useEffect(() => {
    if (openInfo) {
      document.addEventListener('mousedown', handleClickOutsideDiv)
    } else {
      document.removeEventListener('mousedown', handleClickOutsideDiv)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDiv)
    }
  }, [openInfo])

  useEffect(() => {
    if (openInfoE) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [openInfoE])
  const days = ['Lu', 'Ma', 'Mi', 'ju', 'vi', 'Sa', 'Do']
  useEffect(() => {
    setPeriod1([])
    setPeriod2([])
    setAuxPeriod([])
    setAuxPeriod2([])
    const firstUtcStartHour = new Date(firstStartHour.getTime() + offset * 60000)
    const firstUtcEndHour = new Date(firstEndHour.getTime() + offset * 60000)
    const secondUtcStartHour = new Date(secondStartHour.getTime() + offset * 60000)
    const secondUtcEndHour = new Date(secondEndHour.getTime() + offset * 60000)
    if (offset < 0) {
      if (firstStartHour.getDate() === firstUtcStartHour.getDate()) {
        setPeriod1([
          firstUtcStartHour.getHours() + ':' + firstUtcStartHour.getMinutes(),
          firstUtcEndHour.getHours() + ':' + firstUtcEndHour.getMinutes()
        ])

        setPeriod2([
          secondUtcStartHour.getHours() + ':' + secondUtcStartHour.getMinutes(),
          secondUtcEndHour.getHours() + ':' + secondUtcEndHour.getMinutes()
        ])
      } else {
        if (firstEndHour.getDate() === firstUtcEndHour.getDate()) {
          setAuxPeriod([
            firstUtcStartHour.getHours() + ':' + firstUtcStartHour.getMinutes(),
            '23:59'
          ])
          setPeriod1(['00:00', firstUtcEndHour.getHours() + ':' + firstUtcEndHour.getMinutes()])
          setPeriod2([
            secondUtcStartHour.getHours() + ':' + secondUtcStartHour.getMinutes(),
            secondUtcEndHour.getHours() + ':' + secondUtcEndHour.getMinutes()
          ])
          let auxDaysTable = {}
          for (let i = 1; i < days.length; i++) {
            let day = days[i]
            if (typeCheckedBox[day]) {
              let dayss = days[i - 1]
              auxDaysTable[dayss] = true
            }
          }
          if (typeCheckedBox['Lu']) {
            auxDaysTable['Do'] = true
          }
          setAuxDays(auxDaysTable)
        } else {
          if (secondOccupationHoursChecked) {
            if (secondUtcStartHour.getDate() === secondStartHour.getDate()) {
              setAuxPeriod([
                firstUtcStartHour.getHours() + ':' + firstUtcStartHour.getMinutes(),
                firstUtcEndHour.getHours() + ':' + firstUtcEndHour.getMinutes()
              ])
              setPeriod1(['00:00', '00:00'])
              setPeriod2([
                secondUtcStartHour.getHours() + ':' + secondUtcStartHour.getMinutes(),
                secondUtcEndHour.getHours() + ':' + secondUtcEndHour.getMinutes()
              ])
              let auxDaysTable = {}
              for (let i = 1; i < days.length; i++) {
                let day = days[i]
                if (typeCheckedBox[day]) {
                  let dayss = days[i - 1]
                  auxDaysTable[dayss] = true
                }
              }
              if (typeCheckedBox['Lu']) {
                auxDaysTable['Do'] = true
              }
              setAuxDays(auxDaysTable)
            } else {
              if (secondUtcEndHour.getDate() === secondEndHour.getDate()) {
                setAuxPeriod([
                  firstUtcStartHour.getHours() + ':' + firstUtcStartHour.getMinutes(),
                  firstUtcEndHour.getHours() + ':' + firstUtcEndHour.getMinutes()
                ])
                setPeriod1(['00:00', '00:00'])
                setPeriod2([
                  '00:00',
                  secondUtcEndHour.getHours() + ':' + secondUtcEndHour.getMinutes()
                ])
                setAuxPeriod2([
                  secondUtcStartHour.getHours() + ':' + secondUtcStartHour.getMinutes(),
                  '23:59'
                ])
                let auxDaysTable = {}
                for (let i = 1; i < days.length; i++) {
                  let day = days[i]
                  if (typeCheckedBox[day]) {
                    let dayss = days[i - 1]
                    auxDaysTable[dayss] = true
                  }
                }
                if (typeCheckedBox['Lu']) {
                  auxDaysTable['Do'] = true
                }
                setAuxDays(auxDaysTable)
              } else {
                setAuxPeriod([
                  firstUtcStartHour.getHours() + ':' + firstUtcStartHour.getMinutes(),
                  firstUtcEndHour.getHours() + ':' + firstUtcEndHour.getMinutes()
                ])
                setPeriod1(['00:00', '00:00'])
                setPeriod2(['00:00', '00:00'])
                setAuxPeriod2([
                  secondUtcStartHour.getHours() + ':' + secondUtcStartHour.getMinutes(),
                  secondUtcEndHour.getHours() + ':' + secondUtcEndHour.getMinutes()
                ])
                let auxDaysTable = {}
                for (let i = 1; i < days.length; i++) {
                  let day = days[i]
                  if (typeCheckedBox[day]) {
                    let dayss = days[i - 1]
                    auxDaysTable[dayss] = true
                  }
                }
                if (typeCheckedBox['Lu']) {
                  auxDaysTable['Do'] = true
                }
                setAuxDays(auxDaysTable)
              }
            }
          } else {
            setAuxPeriod([
              firstUtcStartHour.getHours() + ':' + firstUtcStartHour.getMinutes(),
              firstUtcEndHour.getHours() + ':' + firstUtcEndHour.getMinutes()
            ])
            setPeriod1(['00:00', '00:00'])
            let auxDaysTable = {}
            for (let i = 1; i < days.length; i++) {
              let day = days[i]
              if (typeCheckedBox[day]) {
                let dayss = days[i - 1]
                auxDaysTable[dayss] = true
              }
            }
            if (typeCheckedBox['Lu']) {
              auxDaysTable['Do'] = true
            }
            setAuxDays(auxDaysTable)
          }
        }
      }
    } else {
      if (secondOccupationHoursChecked) {
        if (secondEndHour.getDate() === secondUtcEndHour.getDate()) {
          setPeriod2([
            secondUtcStartHour.getHours() + ':' + secondUtcStartHour.getMinutes(),
            secondUtcEndHour.getHours() + ':' + secondUtcEndHour.getMinutes()
          ])
          setPeriod1([
            firstUtcStartHour.getHours() + ':' + firstUtcStartHour.getMinutes(),
            firstUtcEndHour.getHours() + ':' + firstUtcEndHour.getMinutes()
          ])
        } else {
          if (secondStartHour.getDate() === secondUtcStartHour.getDate()) {
            let auxDaysTable = {}
            for (let i = 0; i < days.length - 1; i++) {
              let day = days[i]
              if (typeCheckedBox[day]) {
                let dayss = days[i + 1]
                auxDaysTable[dayss] = true
              }
            }
            if (typeCheckedBox['Do']) {
              auxDaysTable['Lu'] = true
            }

            setAuxPeriod2([
              '00:00',
              secondUtcEndHour.getHours() + ':' + secondUtcEndHour.getMinutes()
            ])
            setPeriod1([
              firstUtcStartHour.getHours() + ':' + firstUtcStartHour.getMinutes(),
              firstUtcEndHour.getHours() + ':' + firstUtcEndHour.getMinutes()
            ])
            setPeriod2([
              secondUtcStartHour.getHours() + ':' + secondUtcStartHour.getMinutes(),
              '23:59'
            ])
            setAuxDays(auxDaysTable)
          } else {
            if (firstEndHour.getDate() === firstUtcEndHour.getDate()) {
              let auxDaysTable = {}
              for (let i = 0; i < days.length - 1; i++) {
                let day = days[i]
                if (typeCheckedBox[day]) {
                  let dayss = days[i + 1]
                  auxDaysTable[dayss] = true
                }
              }
              if (typeCheckedBox['Do']) {
                auxDaysTable['Lu'] = true
              }

              setAuxPeriod2([
                secondUtcStartHour.getHours() + ':' + secondUtcStartHour.getMinutes(),
                secondUtcEndHour.getHours() + ':' + secondUtcEndHour.getMinutes()
              ])
              setPeriod1([
                firstUtcStartHour.getHours() + ':' + firstUtcStartHour.getMinutes(),
                firstUtcEndHour.getHours() + ':' + firstUtcEndHour.getMinutes()
              ])
              setPeriod2(['00:00', '00:00'])

              setAuxDays(auxDaysTable)
            } else {
              if (firstUtcStartHour.getDate() === firstStartHour.getDate()) {
                let auxDaysTable = {}
                for (let i = 0; i < days.length - 1; i++) {
                  let day = days[i]
                  if (typeCheckedBox[day]) {
                    let dayss = days[i + 1]
                    auxDaysTable[dayss] = true
                  }
                }
                if (typeCheckedBox['Do']) {
                  auxDaysTable['Lu'] = true
                }

                setAuxPeriod2([
                  secondUtcStartHour.getHours() + ':' + secondUtcStartHour.getMinutes(),
                  secondUtcEndHour.getHours() + ':' + secondUtcEndHour.getMinutes()
                ])
                setPeriod1([
                  firstUtcStartHour.getHours() + ':' + firstUtcStartHour.getMinutes(),
                  '23:59'
                ])
                setPeriod2(['00:00', '00:00'])
                setAuxPeriod([
                  '00:00',
                  firstUtcEndHour.getHours() + ':' + firstUtcEndHour.getMinutes()
                ])
                setAuxDays(auxDaysTable)
              } else {
                let auxDaysTable = {}
                for (let i = 0; i < days.length - 1; i++) {
                  let day = days[i]
                  if (typeCheckedBox[day]) {
                    let dayss = days[i + 1]
                    auxDaysTable[dayss] = true
                  }
                }
                if (typeCheckedBox['Do']) {
                  auxDaysTable['Lu'] = true
                }

                setAuxPeriod2([
                  secondUtcStartHour.getHours() + ':' + secondUtcStartHour.getMinutes(),
                  secondUtcEndHour.getHours() + ':' + secondUtcEndHour.getMinutes()
                ])
                setPeriod1(['00:00', '00:00'])
                setPeriod2(['00:00', '00:00'])
                setAuxPeriod([
                  firstUtcStartHour.getHours() + ':' + firstUtcStartHour.getMinutes(),
                  firstUtcEndHour.getHours() + ':' + firstUtcEndHour.getMinutes()
                ])
                setAuxDays(auxDaysTable)
              }
            }
          }
        }
      } else {
        if (firstEndHour.getDate() === firstUtcEndHour.getDate()) {
          setPeriod1([
            firstUtcStartHour.getHours() + ':' + firstUtcStartHour.getMinutes(),
            firstUtcEndHour.getHours() + ':' + firstUtcEndHour.getMinutes()
          ])
        } else {
          if (firstStartHour.getDate() === firstUtcStartHour.getDate()) {
            setAuxPeriod(['00:00', firstUtcEndHour.getHours() + ':' + firstUtcEndHour.getMinutes()])
            setPeriod1([
              firstUtcStartHour.getHours() + ':' + firstUtcStartHour.getMinutes(),
              '23:59'
            ])
            let auxDaysTable = {}
            for (let i = 1; i < days.length; i++) {
              let day = days[i]
              if (typeCheckedBox[day]) {
                let dayss = days[i - 1]
                auxDaysTable[dayss] = true
              }
            }
            if (typeCheckedBox['Do']) {
              auxDaysTable['Lu'] = true
            }
            setAuxDays(auxDaysTable)
          } else {
            setAuxPeriod([
              firstUtcStartHour.getHours() + ':' + firstUtcStartHour.getMinutes(),
              firstUtcEndHour.getHours() + ':' + firstUtcEndHour.getMinutes()
            ])
            setPeriod1(['00:00', '00:00'])
            let auxDaysTable = {}
            for (let i = 1; i < days.length; i++) {
              let day = days[i]
              if (typeCheckedBox[day]) {
                let dayss = days[i - 1]
                auxDaysTable[dayss] = true
              }
            }
            if (typeCheckedBox['Do']) {
              auxDaysTable['Lu'] = true
            }
            setAuxDays(auxDaysTable)
          }
        }
      }
    }
  }, [secondStartHour, secondEndHour, firstStartHour, firstEndHour, typeCheckedBox])

  const handleChangeSecondOccupationHoursChecked = event => {
    setSecondOccupationHoursChecked(event.target.checked)
  }
  const handleClickPicker = number => {
    setOpenPicker(true)
    setIsActive(number)
  }
  const levelsOptions = [translate('yellowWarning'), translate('redWarning')]

  const handleClose = () => {
    setOpenPicker(false)
  }
  const filterExpressionChange = event => {
    let systemsJS = []
    systemsJS = systems.filter(s => {
      return s.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
    })
    setSystemsGroupState(_.groupBy(systemsJS, 'group'))
  }
  const handleChangeLevelTypes = event => {
    if (event.target.value === 'Personalized') {
      defaultValues = {
        iaq: '',
        covid19: '',
        thermalIndicator: '',
        ventilationIndicator: '',
        tempLow: '',
        tempHigh: '',
        humLow: '',
        humHigh: '',
        co2: '',
        radon: '',
        formaldehyde: '',
        vocs: '',
        vocsIndex: '',
        pm10: '',
        pm25: '',
        pm1: '',
        pm4: '',
        nox: '',
        no2: '',
        o3: '',
        co: ''
      }
      setDefaultValuesState(defaultValues)
      setLevel('select Level')
    }
    setLevelType(event.target.value)
  }
  let defaultValues = {}

  const unityMeasure = {
    iaq: DataUtils.getDataUnit(DataTypes.IAQ, measurementUnits[DataTypes.IAQ]),
    covid19: DataUtils.getDataUnit(DataTypes.COVID_19, measurementUnits[DataTypes.COVID_19]),
    tempLow: DataUtils.getDataUnit(DataTypes.TEMPERATURE, measurementUnits[DataTypes.TEMPERATURE]),
    tempHigh: DataUtils.getDataUnit(DataTypes.TEMPERATURE, measurementUnits[DataTypes.TEMPERATURE]),
    humLow: DataUtils.getDataUnit(DataTypes.HUMIDITY, measurementUnits[DataTypes.HUMIDITY]),
    humHigh: DataUtils.getDataUnit(DataTypes.HUMIDITY, measurementUnits[DataTypes.HUMIDITY]),
    co2: DataUtils.getDataUnit(DataTypes.CO2, measurementUnits[DataTypes.CO2]),
    radon: DataUtils.getDataUnit(DataTypes.RADON, measurementUnits[DataTypes.RADON]),
    formaldehyde: DataUtils.getDataUnit(
      DataTypes.FORMALDEHYDE,
      measurementUnits[DataTypes.FORMALDEHYDE]
    ),
    vocs: DataUtils.getDataUnit(DataTypes.VOCS, measurementUnits[DataTypes.VOCS]),
    vocsIndex: DataUtils.getDataUnit(DataTypes.VOCS_INDEX, measurementUnits[DataTypes.VOCS_INDEX]),
    pm10: DataUtils.getDataUnit(DataTypes.PM10, measurementUnits[DataTypes.PM10]),
    pm25: DataUtils.getDataUnit(DataTypes.PM25, measurementUnits[DataTypes.PM25]),
    pm1: DataUtils.getDataUnit(DataTypes.PM1, measurementUnits[DataTypes.PM1]),
    pm4: DataUtils.getDataUnit(DataTypes.PM4, measurementUnits[DataTypes.PM4]),
    nox: DataUtils.getDataUnit(DataTypes.NOX, measurementUnits[DataTypes.NOX]),
    no2: DataUtils.getDataUnit(DataTypes.NO2, measurementUnits[DataTypes.NO2]),
    o3: DataUtils.getDataUnit(DataTypes.O3, measurementUnits[DataTypes.O3]),
    co: DataUtils.getDataUnit(DataTypes.CO, measurementUnits[DataTypes.CO])
  }
  const { handleSubmit, register, errors, getValues, setError, clearErrors } = useForm()
  const handleChangeCheckbox = event => {
    clearErrors('noChecked')
    setTypeCheckedBox({ ...typeCheckedBox, [event.target.value]: event.target.checked })
  }
  const daysTranslated = [
    translate('addReportModal.monday'),
    translate('addReportModal.tuesday'),
    translate('addReportModal.wednesday'),
    translate('addReportModal.thursday'),
    translate('addReportModal.friday'),
    translate('addReportModal.saturday'),
    translate('addReportModal.sunday')
  ]
  const handleSelectAllDays = event => {
    setIsCheckAllDay(!isCheckAllDay)
    const sTypes = {}
    days.forEach(t => {
      sTypes[t] = true
    })
    setTypeCheckedBox(sTypes)
    if (isCheckAllDay) {
      setTypeCheckedBox({})
    } else {
      clearErrors('noChecked')
    }
  }

  const getParamType = dataType => {
    switch (dataType) {
      case 'tempHigh':
        if (measurementUnits[DataTypes.TEMPERATURE] === MeasurementUnits.FAHRENHEIT) {
          return (
            <Translate
              id={dataType + 'InfoNFahrenheit'}
              options={{ renderInnerHtml: true }}
            />
          )
        } else if (measurementUnits[DataTypes.TEMPERATURE] === MeasurementUnits.KELVIN) {
          return (
            <Translate
              id={dataType + 'InfoNKelvin'}
              options={{ renderInnerHtml: true }}
            />
          )
        } else {
          return (
            <Translate
              id={dataType + 'InfoN'}
              options={{ renderInnerHtml: true }}
            />
          )
        }

      case 'tempLow':
        if (measurementUnits[DataTypes.TEMPERATURE] === MeasurementUnits.FAHRENHEIT) {
          return (
            <Translate
              id={dataType + 'InfoNFahrenheit'}
              options={{ renderInnerHtml: true }}
            />
          )
        } else if (measurementUnits[DataTypes.TEMPERATURE] === MeasurementUnits.KELVIN) {
          return (
            <Translate
              id={dataType + 'InfoNKelvin'}
              options={{ renderInnerHtml: true }}
            />
          )
        } else {
          return (
            <Translate
              id={dataType + 'InfoN'}
              options={{ renderInnerHtml: true }}
            />
          )
        }

      case 'formaldehyde':
        if (measurementUnits[DataTypes.FORMALDEHYDE] === MeasurementUnits.FORMALDEHYDE_PPB) {
          return (
            <Translate
              id={dataType + 'InfoNFormaldehyde'}
              options={{ renderInnerHtml: true }}
            />
          )
        } else {
          return (
            <Translate
              id={dataType + 'InfoN'}
              options={{ renderInnerHtml: true }}
            />
          )
        }

      case 'vocs':
        if (measurementUnits[DataTypes.VOCS] === MeasurementUnits.VOCS_UG_WELL) {
          return (
            <Translate
              id={dataType + 'InfoNVocsWell'}
              options={{ renderInnerHtml: true }}
            />
          )
        } else if (measurementUnits[DataTypes.VOCS] === MeasurementUnits.VOCS_UG_RESET) {
          return (
            <Translate
              id={dataType + 'vocsInfoNVocsReset'}
              options={{ renderInnerHtml: true }}
            />
          )
        } else {
          return (
            <Translate
              id={dataType + 'InfoN'}
              options={{ renderInnerHtml: true }}
            />
          )
        }

      case 'o3':
        if (measurementUnits[DataTypes.O3] === MeasurementUnits.O3_UG) {
          return (
            <Translate
              id={dataType + 'InfoNO3'}
              options={{ renderInnerHtml: true }}
            />
          )
        } else {
          return (
            <Translate
              id={dataType + 'InfoN'}
              options={{ renderInnerHtml: true }}
            />
          )
        }

      case 'co':
        if (measurementUnits[DataTypes.CO] === MeasurementUnits.CO_MG) {
          return (
            <Translate
              id={dataType + 'InfoNCO'}
              options={{ renderInnerHtml: true }}
            />
          )
        } else {
          return (
            <Translate
              id={dataType + 'InfoN'}
              options={{ renderInnerHtml: true }}
            />
          )
        }

      default:
        return (
          <Translate
            id={dataType + 'InfoN'}
            options={{ renderInnerHtml: true }}
          />
        )
    }
  }

  const handleOptionClick = option => {
    clearErrors('typesInput')
    switch (option) {
      case translate('yellowWarning'):
        setLevel(translate('yellowWarning'))
        defaultValues = {
          iaq: DataLimits.INDICATORS_YELLOW,
          covid19: DataLimits.INDICATORS_YELLOW,
          thermalIndicator: DataLimits.INDICATORS_YELLOW,
          ventilationIndicator: DataLimits.INDICATORS_YELLOW,
          tempLow: DataUtils.formatData(20, DataTypes.TEMPERATURE, measurementUnits),
          tempHigh: DataUtils.formatData(23, DataTypes.TEMPERATURE, measurementUnits),
          humLow: '40',
          humHigh: '60',
          co2: DataLimits.CO2_LOW,
          radon: DataLimits.RADON_LOW,
          formaldehyde: DataUtils.formatData(
            DataLimits.FORMALDEHYDE_LOW,
            DataTypes.FORMALDEHYDE,
            measurementUnits
          ),
          vocs: DataUtils.formatData(DataLimits.VOCS_LOW, DataTypes.VOCS, measurementUnits),
          vocsIndex: DataUtils.formatData(
            DataLimits.VOCS_INDEX_LOW,
            DataTypes.VOCS_INDEX,
            measurementUnits
          ),
          pm10: DataLimits.PM10_LOW,
          pm25: DataLimits.PM25_LOW,
          pm1: DataLimits.PM1_LOW,
          pm4: DataLimits.PM4_LOW,
          nox: DataLimits.NOX_LOW,
          no2: DataLimits.NO2_LOW,
          o3: DataUtils.formatData(DataLimits.O3_LOW, DataTypes.O3, measurementUnits),
          co: DataUtils.formatData(DataLimits.CO_LOW, DataTypes.CO, measurementUnits)
        }
        setDefaultValuesState(defaultValues)
        break
      case translate('redWarning'):
        setLevel(translate('redWarning'))
        defaultValues = {
          iaq: DataLimits.INDICATORS_RED,
          covid19: DataLimits.INDICATORS_RED,
          thermalIndicator: DataLimits.INDICATORS_RED,
          ventilationIndicator: DataLimits.INDICATORS_RED,
          tempLow: DataUtils.formatData(18, DataTypes.TEMPERATURE, measurementUnits),
          tempHigh: DataUtils.formatData(26, DataTypes.TEMPERATURE, measurementUnits),
          humLow: '30',
          humHigh: '70',
          co2: DataLimits.CO2_HIGH,
          radon: DataLimits.RADON_HIGH,
          formaldehyde: DataUtils.formatData(
            DataLimits.FORMALDEHYDE_HIGH,
            DataTypes.FORMALDEHYDE,
            measurementUnits
          ),
          vocs: DataUtils.formatData(DataLimits.VOCS_HIGH, DataTypes.VOCS, measurementUnits),
          vocsIndex: DataUtils.formatData(
            DataLimits.VOCS_INDEX_HIGH,
            DataTypes.VOCS_INDEX,
            measurementUnits
          ),
          pm10: DataLimits.PM10_HIGH,
          pm25: DataLimits.PM25_HIGH,
          pm1: DataLimits.PM1_HIGH,
          pm4: DataLimits.PM4_HIGH,
          nox: DataLimits.NOX_HIGH,
          no2: DataLimits.NO2_HIGH,
          o3: DataUtils.formatData(DataLimits.O3_HIGH, DataTypes.O3, measurementUnits),
          co: DataUtils.formatData(DataLimits.CO_HIGH, DataTypes.CO, measurementUnits)
        }
        setDefaultValuesState(defaultValues)
        break
      default:
        break
    }
    setAnchorEl(null)
  }

  const handleChangeSelectedEmails = event => {
    if (event.target.checked) {
      clearErrors('emailRecipients')
    }
    setEmails({
      ...emails,
      [event.target.value]: event.target.checked
    })
  }

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #dddddd',
      'border-radius': '13px',
      padding: ' 0',
      transition: 'color .3s ease',
      cursor: 'pointer',
      color: '#333333',
      width: '200px',
      borderRadius: '13px'
    }
  })(props => <Menu {...props} />)

  const handleLevel = event => {
    if (anchorEl) {
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleInputChange = (event, dataType) => {
    setDefaultValuesState({
      ...defaultValuesState,
      [dataType]: event.target.value
    })
    if (!checkTypeRange(event.target.value, dataType)) {
      setError('typesInput.' + dataType, { shouldFocus: 'typesInput' })
    } else {
      clearErrors('typesInput.' + dataType)
    }
  }
  const handleInfo = keyInfo => {
    const openDataType = openInfo[keyInfo]
    setOpenInfo({ ...openInfo, [keyInfo]: !openDataType })
    if (infoTab === keyInfo) {
      setInfoTab('')
    } else {
      setInfoTab(keyInfo)
    }
  }

  const handleInfoE = () => {
    setOpenInfoE(!openInfoE)
  }

  const getTypeInfoLink = type => {
    if (type === 'tempLow' || type === 'tempHigh') {
      type = DataTypes.TEMPERATURE
    }
    if (type === 'humLow' || type === 'humHigh') {
      type = DataTypes.HUMIDITY
    }
    switch (type) {
      case DataTypes.TEMPERATURE:
        return 'https://www.inbiot.es/wikinbiot/temperature'
      case DataTypes.HUMIDITY:
        return 'https://www.inbiot.es/wikinbiot/relative-humidity'
      case DataTypes.CO2:
        return 'https://www.inbiot.es/wikinbiot/co2'
      case DataTypes.FORMALDEHYDE:
        return 'https://www.inbiot.es/wikinbiot/formaldehyde'
      case DataTypes.VOCS:
        return 'https://www.inbiot.es/wikinbiot/tvoc'
      case DataTypes.VOCS_INDEX:
        return 'https://www.inbiot.es/wikinbiot/tvoc'
      case DataTypes.PM25:
        return 'https://www.inbiot.es/wikinbiot/particulate-matter'
      case DataTypes.PM4:
        return 'https://www.inbiot.es/wikinbiot/particulate-matter'
      case DataTypes.PM1:
        return 'https://www.inbiot.es/wikinbiot/particulate-matter'
      case DataTypes.PM10:
        return 'https://www.inbiot.es/wikinbiot/particulate-matter'
      case DataTypes.CO:
        return 'https://www.inbiot.es/wikinbiot/carbon-monoxide'
      case DataTypes.O3:
        return 'https://www.inbiot.es/wikinbiot/ozone'
      case DataTypes.RADON:
        return 'https://www.inbiot.es/wikinbiot'
      case DataTypes.NOISE:
        return 'https://www.inbiot.es/wikinbiot'
      case DataTypes.NOX:
        return 'https://www.inbiot.es/wikinbiot'
      case DataTypes.NO2:
        return 'https://www.inbiot.es/wikinbiot/nitrogen-dioxide'
      case DataTypes.LIGHT:
        return 'https://www.inbiot.es/wikinbiot'
      case DataTypes.COVID_19:
        return 'https://inbiot.es/wikinbiot/indicador-resistencia-virus'
      case DataTypes.VENTILATION_INDICATOR:
        return 'https://www.inbiot.es/wikinbiot/indicador-eficacia-ventilacion'
      case DataTypes.THERMAL_INDICATOR:
        return 'https://inbiot.es/wikinbiot/indicadore-confort-termohigrometrico'
      case DataTypes.IAQ:
        return 'https://inbiot.es/wikinbiot/indicador-calidad-de-aire-interior'
      default:
        return 'https://www.inbiot.es/wikinbiot'
    }
  }

  const handleAddEmailChange = event => {
    setAddEmail(event.target.value)
    if (event.target.value !== '') {
      clearErrors('emailForAdd')
    }
  }
  const handleChangeAlarmName = event => {
    setAlarmName(event.target.value)
    if (event.target.value !== '') {
      clearErrors('alarmNameInvalid')
    }
  }

  const onClickNext = () => {
    const values = getValues()
    if (activeTab === 0) {
      let valueError = false
      let typeError = ''
      for (let i = 0; i < Object.keys(values.typesInput).length; i++) {
        if (
          values.typesInput[Object.keys(values.typesInput)[i]] === '' ||
          !checkTypeRange(
            values.typesInput[Object.keys(values.typesInput)[i]],
            Object.keys(values.typesInput)[i]
          )
        ) {
          valueError = true
          typeError = Object.keys(values.typesInput)[i]
          break
        }
      }
      if (valueError) {
        setError('typesInput.' + typeError, { shouldFocus: 'typesInput' })
      } else {
        setActiveTab(1)
      }
    } else if (activeTab === 1) {
      if (!Array.isArray(values.selectedDevices)) values.selectedDevices = [values.selectedDevices]
      if (
        values &&
        values.selectedDevices !== undefined &&
        values.selectedDevices.length > 0 &&
        values.selectedDevices[0] !== false
      ) {
        setActiveTab(2)
      } else {
        setError('selectedDevices', { shouldFocus: ' ' })
      }
    }
  }
  const onClickBack = () => {
    if (activeTab === 2) {
      setActiveTab(1)
      clearErrors('types')
    }
    if (activeTab === 1) {
      setActiveTab(0)
    }
  }
  const onClickCreate = () => {
    const values = getValues()
    const auxTypeSelected = []
    days.forEach(t => {
      if (typeCheckedBox[t]) {
        auxTypeSelected.push(t)
      }
    })
    if (values.emailRecipients.length === 0) {
      setError('emailRecipients', { shouldFocus: 'emailRecipients' })
    } else {
      values.selectedDevices = Array.isArray(values.selectedDevices)
        ? values.selectedDevices
        : [values.selectedDevices]
      values.emailRecipients = Array.isArray(values.emailRecipients)
        ? values.emailRecipients
        : [values.emailRecipients]
    }
    if (auxTypeSelected.length !== 0) {
      clearErrors('noChecked')
    } else {
      setError('noChecked', { shouldFocus: 'noChecked' })
    }
    if (alarmName.length !== 0) {
      values.alarmName = alarmName
      clearErrors('alarmNameInvalid')
    } else {
      setError('alarmNameInvalid', { shouldFocus: 'alarmNameInvalid' })
    }
    if (firstStartHour.getHours() > firstEndHour.getHours()) {
      setError('fourDates', { shouldFocus: 'fourDates' })
    } else if (
      firstStartHour.getHours() === firstEndHour.getHours() &&
      firstStartHour.getMinutes() >= firstEndHour.getMinutes()
    ) {
      setError('fourDates', { shouldFocus: 'fourDates' })
    } else if (
      secondOccupationHoursChecked &&
      secondStartHour.getHours() > secondEndHour.getHours()
    ) {
      setError('fourDates', { shouldFocus: 'fourDates' })
    } else if (
      secondOccupationHoursChecked &&
      secondStartHour.getHours() === secondEndHour.getHours() &&
      secondStartHour.getMinutes() >= secondEndHour.getMinutes()
    ) {
      setError('fourDates', { shouldFocus: 'fourDates' })
    } else if (
      secondOccupationHoursChecked &&
      secondEndHour.getHours() <= firstStartHour.getHours()
    ) {
      setError('fourDates', { shouldFocus: 'fourDates' })
    } else if (
      secondOccupationHoursChecked &&
      secondStartHour.getHours() < firstEndHour.getHours()
    ) {
      setError('fourDates', { shouldFocus: 'fourDates' })
    } else if (
      secondOccupationHoursChecked &&
      secondStartHour.getHours() === firstEndHour.getHours() &&
      secondStartHour.getMinutes() <= firstEndHour.getMinutes()
    ) {
      setError('fourDates', { shouldFocus: 'fourDates' })
    } else {
      clearErrors('fourDates')
    }
    values.occupancyDays = typeCheckedBox
    values.period1 = period1
    if (secondOccupationHoursChecked) {
      values.period2 = period2
    }
    if (auxPeriod.length > 0) {
      values.auxPeriod = auxPeriod
      values.auxDays = auxDays
    }
    if (auxPeriod2.length > 0) {
      values.auxPeriod2 = auxPeriod2
    }
    values.types = selectedTypes
    if (Object.keys(errors).length === 0) {
      onSubmit(values)
    }
  }

  const onClickAdd = () => {
    const emailFormat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (getValues('emailForAdd') !== '' && getValues('emailForAdd').match(emailFormat)) {
      addAlertRecipient({
        email: getValues('emailForAdd'),
        userId: user.toJS()._id
      })
      setSearched([...searched, { email: getValues('emailForAdd'), confirmed: false }])
    } else {
      setError('emailForAdd', { shouldFocus: 'emailForAdd' })
    }
  }

  const onClickCancel = () => {
    props.hideModal()
  }

  const checkTypeRange = (value, type) => {
    switch (type) {
      case DataTypes.CO2:
        return value > 400
      default:
        return value > 0
    }
  }

  const getRangeErrorText = dataType => {
    switch (dataType) {
      case DataTypes.CO2:
        return translate('addAlertModal.rangeCo2Error')
      default:
        return translate('addAlertModal.rangeError')
    }
  }

  const checkTypeError = dataType => {
    if (errors.typesInput) {
      return errors.typesInput[dataType]
    }
  }

  const error = () => {
    if (!submitError) return null
    let message = translate('submitErrors.unknown')
    if (submitError.res && submitError.res.status === 404) {
      message = translate('submitErrors.systemNotFound')
    } else if (submitError.res && submitError.res.status === 409) {
      message = translate('submitErrors.systemAlreadyInUse')
    }

    return (
      <Alert
        severity='error'
        className={styles.error}
      >
        {message}
      </Alert>
    )
  }

  useEffect(() => {
    const types = []
    const sTypes = []
    const dfValues = {}
    setAlarmName(alarm.name)
    const system = systems.filter(s => {
      return alarm.DeviceIds.includes(s._id)
    })

    for (let i = 0; i < system.length; i++) {
      const sortedData = SystemUtils.getDataSorted(system[i])
      sortedData.map(d => {
        if (types.indexOf(d.type) === -1) {
          if (
            types.indexOf('tempHigh') === -1 &&
            types.indexOf('tempLow') === -1 &&
            (d.type === DataTypes.TEMPERATURE || d.type === DataTypes.RADIANT_TEMPERATURE)
          ) {
            types.push('tempHigh', 'tempLow')
          } else if (
            types.indexOf('humHigh') === -1 &&
            types.indexOf('humLow') === -1 &&
            d.type === DataTypes.HUMIDITY
          ) {
            types.push('humHigh', 'humLow')
          } else if (
            d.type !== DataTypes.HUMIDITY &&
            d.type !== DataTypes.TEMPERATURE &&
            d.type !== DataTypes.RADIANT_TEMPERATURE
          ) {
            types.push(d.type)
          }
        }
        return null
      })
    }

    // We add temperature and humidity checkboxes
    const tempHumTypes = ['tempLow', 'tempHigh', 'humLow', 'humHigh']
    for (let i = 0; i < tempHumTypes.length; i++) {
      if (alarm.Meta[tempHumTypes[i]]) {
        sTypes.push(tempHumTypes[i])
        dfValues[tempHumTypes[i]] = alarm.Meta[tempHumTypes[i]]
      }
    }
    types.map(d => {
      if (d === 'tempHigh' || d === 'tempLow' || d === 'humLow' || d === 'humHigh') {
        return null
      }
      if (alarm.Meta[d]) {
        sTypes.push(d)
        dfValues[d] = alarm.Meta[d]
      }
      return null
    })
    setSelectedTypes(sTypes)
    const emails = {}
    for (let i = 0; i < alarm.emailRecipients?.length; i++) {
      emails[alarm.emailRecipients[i]] = true
    }
    if (!alarm.emailRecipients) emails[user.toJS().email] = true
    setEmails(emails)
    let keys = Object.keys(dfValues)
    let values = Object.values(dfValues)

    let transformedValues = {}
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === 'tempLow' || keys[i] === 'tempHigh') {
        transformedValues[keys[i]] = DataUtils.formatData(
          values[i],
          DataTypes.TEMPERATURE,
          measurementUnits
        )
      } else {
        transformedValues[keys[i]] = DataUtils.formatData(values[i], keys[i], measurementUnits)
      }
    }
    setSelectedDevices(system.map(s => s._id))
    setByDefaultSelcetedDevices(system.map(s => s._id))
    setDefaultValuesState(transformedValues)
  }, [alarm.DeviceId, alarm.Meta, alarm.emailRecipients, systems])
  const isIndeterminateDevices = () => {
    return (
      checkAllDevicesIsClicked &&
      selectedDevices.length > 0 &&
      selectedDevices.length !== systems.length
    )
  }
  const handleSelectAllDevices = event => {
    setIsCheckAllDevices(!isCheckAllDevices)
    setCheckDevicesAllIsClicked(true)
    if (isCheckAllDevices) {
      setSelectedDevices([])
    } else {
      const systemIds = []
      systems.map(s => {
        const alarmParamsKeys = []
        let hasSameValues = false
        if (s.alarmParams && !byDefaultSelcetedDevices.includes(s._id)) {
          alarmParamsKeys.push(...Object.keys(s.alarmParams))
          const activeIndex = Object.keys(s.alarmParams).indexOf('active')
          if (activeIndex > -1) {
            alarmParamsKeys.splice(activeIndex, 1)
          }
          hasSameValues = selectedTypes.every(key => alarmParamsKeys.includes(key))
        }
        const sortedData = SystemUtils.getDataSorted(s)
        let hasType = false
        if (selectedTypes[0] === 'tempHigh' || selectedTypes[0] === 'tempLow') {
          hasType = sortedData.find(
            item =>
              item.type === DataTypes.TEMPERATURE || item.type === DataTypes.RADIANT_TEMPERATURE
          )
        } else if (selectedTypes[0] === 'humHigh' || selectedTypes[0] === 'humLow') {
          hasType = sortedData.find(item => item.type === DataTypes.HUMIDITY)
        } else {
          hasType = sortedData.find(item => item.type === selectedTypes[0])
        }

        if (hasType && !hasSameValues) {
          systemIds.push(s._id)
        }
      })
      setSelectedDevices(systemIds)
      clearErrors('selectedDevices')
    }
  }
  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }
  const handleSelectAllGroupDevices = (event, systems) => {
    if (event.target.checked) {
      const aux = []
      for (let i = 0; i < systems.length; i++) {
        const alarmParamsKeys = []
        let hasSameValues = false
        if (systems[i].alarmParams && !byDefaultSelcetedDevices.includes(systems[i]._id)) {
          alarmParamsKeys.push(...Object.keys(systems[i].alarmParams))
          const activeIndex = Object.keys(systems[i].alarmParams).indexOf('active')
          if (activeIndex > -1) {
            alarmParamsKeys.splice(activeIndex, 1)
          }
          hasSameValues = selectedTypes.every(key => alarmParamsKeys.includes(key))
        }
        const sortedData = SystemUtils.getDataSorted(systems[i])
        let hasType = false
        if (selectedTypes[0] === 'tempHigh' || selectedTypes[0] === 'tempLow') {
          hasType = sortedData.find(
            item =>
              item.type === DataTypes.TEMPERATURE || item.type === DataTypes.RADIANT_TEMPERATURE
          )
        } else if (selectedTypes[0] === 'humHigh' || selectedTypes[0] === 'humLow') {
          hasType = sortedData.find(item => item.type === DataTypes.HUMIDITY)
        } else {
          hasType = sortedData.find(item => item.type === selectedTypes[0])
        }
        if (!selectedDevices.includes(systems[i]._id) && hasType && !hasSameValues)
          aux.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices => [...selectedDevices, ...aux])
      clearErrors('selectedDevices')
    } else {
      const systemIds = []
      for (let i = 0; i < systems.length; i++) {
        systemIds.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices.filter(s => !systemIds.includes(s)))
      clearErrors('selectedDevices')
    }
  }

  const handleChangeDevice = event => {
    const { checked, value } = event.target
    if (checked) {
      setSelectedDevices([...selectedDevices, value])
      clearErrors('selectedDevices')
    } else {
      setSelectedDevices(selectedDevices.filter(item => item !== value))
    }
  }
  const typeValid = system => {
    const alarmParamsKeys = []
    let hasSameValues = false
    if (system.alarmParams && !byDefaultSelcetedDevices.includes(system._id)) {
      alarmParamsKeys.push(...Object.keys(system.alarmParams))
      const activeIndex = Object.keys(system.alarmParams).indexOf('active')
      if (activeIndex > -1) {
        alarmParamsKeys.splice(activeIndex, 1)
      }
      hasSameValues = selectedTypes.every(key => alarmParamsKeys.includes(key))
    }
    const sortedData = SystemUtils.getDataSorted(system)
    let hasType = false
    if (selectedTypes[0] === 'tempHigh' || selectedTypes[0] === 'tempLow') {
      hasType = sortedData.find(
        item => item.type === DataTypes.TEMPERATURE || item.type === DataTypes.RADIANT_TEMPERATURE
      )
    } else if (selectedTypes[0] === 'humHigh' || selectedTypes[0] === 'humLow') {
      hasType = sortedData.find(item => item.type === DataTypes.HUMIDITY)
    } else {
      hasType = sortedData.find(item => item.type === selectedTypes[0])
    }

    if (hasType && !hasSameValues) {
      return true
    }
  }

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('alertsPage.editAlert')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderDisabled}>
                    {translate('editParameterAlertModal.navParams')}
                  </Typography>
                  <Typography className={styles.subheaderValuesSelected}>
                    {translate('editParameterAlertModal.navValues')}
                  </Typography>
                  <Typography className={styles.subheaderParams}>
                    {translate('addParameterAlertModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderParams}>
                    {translate('addParameterAlertModal.navEmails')}
                  </Typography>
                </div>
                <div className={styles.spaceBetween}>
                  <div className={styles.subInfo}>
                    <Typography>{translate('alertValue')}</Typography>
                  </div>
                  <div className={styles.wrapperDefaultValues}>
                    <div
                      key='Predetermine'
                      className={styles.radioFormDiv}
                    >
                      <FormControlLabel
                        className={styles.checkboxForm}
                        control={
                          <Radio
                            className={cx(styles.checkbox)}
                            onChange={handleChangeLevelTypes}
                            checked={levelType === 'Predetermined'}
                            value='Predetermined'
                            id='Predetermined'
                            classes={{ checked: styles.checkedBox }}
                            required
                            name='Predetermined'
                            inputRef={register()}
                            disableRipple
                          />
                        }
                        label={translate('predetermined')}
                      />
                    </div>

                    <div className={styles.values}>
                      <Button
                        disabled={levelType === 'Personalized'}
                        disableRipple
                        aria-controls='fade-menu'
                        aria-haspopup='true'
                        onClick={handleLevel}
                        className={cx(styles.navItem, styles.buttonLanguages)}
                      >
                        {level}
                        <KeyboardArrowDownIcon />
                      </Button>
                      <StyledMenu
                        elevation={0}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                        id='fade-menu'
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        // onClose={handleCloseMenu}
                        TransitionComponent={Fade}
                        className={styles.menu}
                      >
                        {levelsOptions.map((option, index) => {
                          let isSelected = false
                          if (option === level) {
                            isSelected = true
                          }
                          return (
                            <MenuItem
                              key={index}
                              onClick={() => handleOptionClick(option)}
                              className={cx(
                                { [styles.menuItem]: !isSelected },
                                { [styles.menuItemFocus]: isSelected }
                              )}
                            >
                              {option}
                            </MenuItem>
                          )
                        })}
                      </StyledMenu>
                    </div>
                  </div>
                  <FormControlLabel
                    className={styles.checkboxForm}
                    control={
                      <Radio
                        checked={levelType === 'Personalized'}
                        onChange={handleChangeLevelTypes}
                        value='Personalized'
                        className={cx(styles.checkbox)}
                        id='Personalized'
                        classes={{ checked: styles.checkedBox }}
                        required
                        name='Personalized'
                        inputRef={register()}
                        disableRipple
                      />
                    }
                    label={translate('Personalized')}
                  />
                  <div className={styles.paramWrapper}>
                    {selectedTypes.map((dataType, i) => {
                      return (
                        <div
                          className={styles.wrap}
                          key={'dataType' + i}
                        >
                          <div className={styles.wrapperInfo}>
                            <div
                              className={
                                levelType === 'Personalized'
                                  ? styles.dataInputEnable
                                  : styles.dataInput
                              }
                            >
                              {translate(dataType)}
                              <div className={styles.wrapperInfoButton}>
                                <IconButton
                                  ref={buttonRefIcon}
                                  className={styles.infoButton}
                                  variant='outlined'
                                  // onClick={setOpenInfo(dataType)}
                                  onClick={() => handleInfo(dataType)}
                                  value={getValues(`types[${i}]`)}
                                  name='infoParam'
                                  disableRipple
                                >
                                  <InfoOutlinedIcon />
                                </IconButton>
                              </div>
                            </div>
                            <div className={styles.wrapperDataInfo}>
                              <div className={styles.inputField}>
                                <Input
                                  classes={{
                                    checked: styles.checkedBox,
                                    input: styles.inputUnit
                                  }}
                                  disabled={levelType === 'Predetermined'}
                                  onChange={event => handleInputChange(event, dataType)}
                                  required
                                  error={checkTypeError(dataType)}
                                  position='end'
                                  type='number'
                                  name={`typesInput[${dataType}]`}
                                  value={defaultValuesState[dataType]}
                                  // placeholder={unityMeasure[dataType]}
                                  defaultValue={defaultValuesState[dataType]}
                                  inputRef={register()}
                                  disableRipple
                                />
                              </div>
                              <div className={styles.inputUnity}>{unityMeasure[dataType]}</div>
                            </div>
                          </div>
                          <div
                            ref={infoTabRef}
                            style={{
                              display: infoTab === dataType ? 'block' : 'none'
                            }}
                            className={styles.caja}
                          >
                            {getParamType(dataType)}
                            <Button
                              fullWidth
                              disableRipple
                              variant='contained'
                              className={styles.buttonSiguiente}
                              onClick={e => {
                                e.preventDefault()
                                window.open(getTypeInfoLink(dataType), '_blank')
                              }}
                            >
                              <a
                                href={getTypeInfoLink(dataType)}
                                target='_blank'
                                rel='noopener noreferrer'
                              />
                              {translate('infoModal.moreInfo')}
                              {/* <Link to='https://wiki.inbiot.es'>{translate('infoModal.moreInfo')}</Link> */}
                            </Button>
                          </div>
                          <div className={styles.wrapperError}>
                            {errors.typesInput && errors.typesInput[dataType] && (
                              <div className={styles.error}>
                                <small>{getRangeErrorText(dataType)}</small>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonSiguiente1}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickNext}
                  >
                    {translate('next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderDisabled}>
                    {translate('addParameterAlertModal.navParams')}
                  </Typography>
                  <Typography className={styles.subheaderValues}>
                    {translate('addParameterAlertModal.navValues')}
                  </Typography>
                  <Typography className={styles.subheaderDevicesSelected}>
                    {translate('addParameterAlertModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderParams}>
                    {translate('addParameterAlertModal.navEmails')}
                  </Typography>
                </div>
                <TextField
                  variant='outlined'
                  fullWidth
                  type='search'
                  onChange={filterExpressionChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                  id='searchDevice'
                  name='searchDevice'
                  placeholder={translate('searchDevice')}
                  className={cx(styles.input, classes.input, {
                    [styles.hasError]: errors.alarmName
                  })}
                />
                <FormControlLabel
                  className={styles.selectAllCheckboxForm}
                  control={
                    <Checkbox
                      className={cx(styles.checkbox)}
                      classes={{ checked: styles.checkedBox }}
                      name='selectAllDevices'
                      id='selectAllDevices'
                      indeterminate={isIndeterminateDevices()}
                      onChange={handleSelectAllDevices}
                      checked={isCheckAllDevices}
                    />
                  }
                  label={translate('downloadDataForm.selectAll')}
                />
                <Paper
                  className={styles.devicesListPaper}
                  classes={{ root: classes.paper }}
                >
                  <List
                    disableTouchRipple
                    className={styles.devicesList}
                  >
                    {_.map(systemsGroupState, function (systems, group) {
                      const systemsType = _.groupBy(systems, 'micaType')
                      return (
                        <div className={styles.noGroupWrapper}>
                          {group !== 'undefined' && (
                            <div>
                              <ListItem
                                button
                                disableTouchRipple
                                className={styles.groupListItem}
                              >
                                {!open[group] ? (
                                  <ExpandLess
                                    onClick={() => handleClick(group)}
                                    className={styles.expandIcon}
                                  />
                                ) : (
                                  <ExpandMore
                                    onClick={() => handleClick(group)}
                                    className={styles.expandIcon}
                                  />
                                )}
                                <Checkbox
                                  className={cx(styles.checkbox)}
                                  classes={{ checked: styles.checkedBox }}
                                  name={group}
                                  onChange={e => handleSelectAllGroupDevices(e, systems)}
                                  id={group}
                                />
                                <ListItemText
                                  primary={group}
                                  className={styles.listItemText}
                                />
                              </ListItem>
                            </div>
                          )}
                          {group === 'undefined' && (
                            <div>
                              {_.map(systemsType, function (systems, type) {
                                return (
                                  <div className={styles.typeRowWrapper}>
                                    <ListItem
                                      button
                                      disableTouchRipple
                                      className={styles.typeListItem}
                                    >
                                      {!open[type] ? (
                                        <ExpandLess
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      ) : (
                                        <ExpandMore
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      )}
                                      <ListItemText
                                        primary={type}
                                        className={styles.micaTypeItemText}
                                      />
                                    </ListItem>
                                    <Collapse
                                      in={!open[type]}
                                      timeout='auto'
                                      unmountOnExit
                                    >
                                      {systems.map((system, index) => {
                                        return (
                                          <div
                                            key={system._id}
                                            className={styles.micaItem}
                                          >
                                            <FormControlLabel
                                              className={styles.checkboxForm}
                                              control={
                                                <Checkbox
                                                  className={cx(styles.checkbox)}
                                                  classes={{
                                                    checked: styles.checkedBox,
                                                    disabled: styles.disabledBox
                                                  }}
                                                  checked={selectedDevices.includes(system._id)}
                                                  onChange={handleChangeDevice}
                                                  required
                                                  outline='true'
                                                  inputRef={register()}
                                                  disableRipple
                                                  disabled={!typeValid(system)}
                                                  name={`selectedDevices[]`}
                                                  value={system._id}
                                                />
                                              }
                                              label={system.name}
                                            />
                                          </div>
                                        )
                                      })}
                                    </Collapse>
                                  </div>
                                )
                              })}
                            </div>
                          )}

                          {group !== 'undefined' && (
                            <Collapse
                              in={!open[group]}
                              timeout='auto'
                              unmountOnExit
                              classes={{
                                wrapper: classes.wrapper
                              }}
                            >
                              {_.map(systemsType, function (systems, type) {
                                return (
                                  <div className={styles.typeRowWrapper}>
                                    <ListItem
                                      button
                                      disableTouchRipple
                                      className={styles.typeListItem}
                                    >
                                      {!open[type] ? (
                                        <ExpandLess
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      ) : (
                                        <ExpandMore
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      )}
                                      <ListItemText
                                        primary={translate(type)}
                                        className={styles.micaTypeItemText}
                                      />
                                    </ListItem>
                                    <Collapse
                                      in={!open[type]}
                                      timeout='auto'
                                      unmountOnExit
                                    >
                                      {systems.map((system, index) => {
                                        return (
                                          <div
                                            key={'system' + index}
                                            className={styles.micaItem}
                                          >
                                            <FormControlLabel
                                              className={styles.checkboxForm}
                                              control={
                                                <Checkbox
                                                  className={cx(styles.checkbox)}
                                                  classes={{
                                                    checked: styles.checkedBox,
                                                    disabled: styles.disabledBox
                                                  }}
                                                  checked={selectedDevices.includes(system._id)}
                                                  onChange={handleChangeDevice}
                                                  required
                                                  outline='true'
                                                  inputRef={register()}
                                                  disableRipple
                                                  disabled={!typeValid(system)}
                                                  name={`selectedDevices[]`}
                                                  value={system._id}
                                                />
                                              }
                                              label={system.name}
                                            />
                                          </div>
                                        )
                                      })}
                                    </Collapse>
                                  </div>
                                )
                              })}
                            </Collapse>
                          )}
                        </div>
                      )
                    })}
                  </List>
                </Paper>
                <div className={styles.wrapperError}>
                  {errors.selectedDevices && (
                    <div className={styles.error}>
                      <small>{translate('validation.anyDeviceRequired')}</small>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonSiguiente1}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickNext}
                  >
                    {translate('next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('back')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 2 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderDisabled}>
                    {translate('editParameterAlertModal.navParams')}
                  </Typography>
                  <Typography className={styles.subheaderValues}>
                    {translate('editParameterAlertModal.navValues')}
                  </Typography>
                  <Typography className={styles.subheaderParams}>
                    {translate('addParameterAlertModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderParamsSelected}>
                    {translate('addParameterAlertModal.navEmails')}
                  </Typography>
                </div>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  id='alarmsName'
                  onChange={handleChangeAlarmName}
                  label={translate('alertsPage.alarmsName')}
                  onKeyPress={e => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  inputRef={register()}
                  name='alarmsName'
                  autoComplete='alarmsName'
                  value={alarmName}
                  className={cx(styles.input, classes.input, {
                    [styles.hasError]: errors.alarmName
                  })}
                />
                {errors.alarmNameInvalid && (
                  <div className={styles.error}>
                    <small>{translate('validation.required')}</small>
                  </div>
                )}
                <TextField
                  variant='outlined'
                  type='email'
                  fullWidth
                  defaultValue=' '
                  value={addEmail}
                  id='emailForAdd'
                  label={translate('alertsPage.addEmail')}
                  onKeyPress={e => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  onChange={handleAddEmailChange}
                  inputRef={register({
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                    }
                  })}
                  name='emailForAdd'
                  autoComplete='emailForAdd'
                  helperText={`${addEmail.length}/128`}
                  inputProps={{ maxLength: 128 }}
                  className={cx(styles.input, classes.input, {
                    [styles.hasError]: errors.emailForAdd
                  })}
                />
                {errors.emailForAdd && (
                  <div className={styles.error}>
                    <small>{translate('validation.inValidEmail')}</small>
                  </div>
                )}
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonAdd}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickAdd}
                  >
                    {translate('add')}
                  </Button>
                </div>

                <div className={styles.wrapperInfo}>
                  <div className={styles.dataInput}>
                    {translate('selectEmails')}
                    <div className={styles.wrapperInfoButton}>
                      <IconButton
                        ref={buttonRef}
                        className={styles.infoButton}
                        variant='outlined'
                        // onClick={setOpenInfo(dataType)}
                        onClick={() => handleInfoE()}
                        value={' '}
                        name='infoParam'
                        disableRipple
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>

                  <div className={styles.wrapperDataInfo}>
                    <div
                      ref={infoDivRef}
                      style={{
                        display: !openInfoE ? 'none' : 'block'
                      }}
                      className={styles.cajaEmail}
                    >
                      {
                        <Translate
                          id={'emailInfoN'}
                          options={{ renderInnerHtml: true }}
                        />
                      }
                    </div>
                  </div>
                </div>
                <div className={styles.checkboxEmailWrapper}>
                  <div className={styles.checkboxFormDiv}>
                    <FormControlLabel
                      className={(styles.checkboxForm, styles.confirmed)}
                      control={
                        <Checkbox
                          className={cx(styles.checkbox)}
                          id={user.toJS().email}
                          onChange={handleChangeSelectedEmails}
                          classes={{ checked: styles.checkedBox }}
                          required
                          checked={emails[user.toJS().email] === true}
                          name='emailRecipients'
                          value={user.toJS().email}
                          inputRef={register()}
                          disableRipple
                        />
                      }
                      label={user.toJS().email + ' ' + translate('thisUser')}
                    />
                  </div>
                  {searched.map((email, i) => {
                    return (
                      <div
                        key={i + 'email'}
                        className={styles.checkboxFormDiv}
                      >
                        <FormControlLabel
                          className={
                            (styles.checkboxForm,
                            `${
                              email.confirmed ? `${styles.confirmed}` : `${styles.notConfirmed}`
                            } `)
                          }
                          control={
                            <Checkbox
                              className={cx(styles.checkbox)}
                              id={email}
                              onChange={handleChangeSelectedEmails}
                              classes={{ checked: styles.checkedBox }}
                              required
                              checked={emails[email.email] === true}
                              name='emailRecipients'
                              value={email.email}
                              inputRef={register()}
                              disableRipple
                            />
                          }
                          label={email.email}
                        />
                        <IconButton
                          onClick={() => onDeleteAlertRecipient(email.email)}
                          className={styles.deleteEmailRecipientIcon}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </div>
                    )
                  })}
                </div>
                {errors.emailRecipients && (
                  <div className={styles.error}>
                    <small>{translate('validation.anyEmailRequired')}</small>
                  </div>
                )}
                <div className={styles.selectDays}>{translate('listDays')}</div>
                <div className={styles.wrapperDefaultValues}>
                  <FormControlLabel
                    className={styles.selectAllCheckboxForm}
                    control={
                      <Checkbox
                        className={cx(styles.checkbox)}
                        classes={{ checked: styles.checkedBox }}
                        name='selectAllDay'
                        id='selectAllDay'
                        onChange={handleSelectAllDays}
                        isChecked={isCheckAllDay}
                      />
                    }
                    label={translate('addAlertModal.selectAll')}
                  />
                </div>
                <table className={styles.checkboxFormDivA}>
                  <tbody>
                    <tr>
                      {daysTranslated.map((column, index) => (
                        <td
                          key={index + 'daysTranslated'}
                          className={styles.subInfo1}
                        >
                          {column}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      {days.map((column, index) => (
                        <td
                          key={'day' + index}
                          className={styles.subInfo1}
                        >
                          <FormControlLabel
                            className={styles.checkboxFormDays}
                            control={
                              <Checkbox
                                className={cx(styles.checkboxAligned)}
                                classes={{ checked: styles.checkedBox }}
                                checked={typeCheckedBox[column] === true}
                                required
                                name={'column'}
                                value={column}
                                onChange={handleChangeCheckbox}
                                disableRipple
                                rules={{ required: true, validate: onClickCreate }}
                              />
                            }
                          />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
                {errors.noChecked && (
                  <div className={styles.error}>
                    <small>{translate('validation.anyField')}</small>
                  </div>
                )}
                <div className={styles.occupationHourRow}>
                  <Typography className={styles.occupationHourTitle}>
                    {translate('addReportModal.firstOccupationHours')}
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DemoContainer components={['TimePicker']}>
                      <div className={styles.firstOccupancyTime}>
                        <div
                          className={styles.timePickerContent}
                          onClick={() => handleClickPicker(1)}
                        >
                          <DesktopTimePicker
                            timeSteps={{ hours: 1, minutes: 30, seconds: 5 }}
                            components={{ ActionBar: 'none' }}
                            value={firstStartHour}
                            onChange={newValue => setFirstStartHour(newValue)}
                            open={openPicker && isActive === 1}
                            onClose={handleClose}
                            className={cx(styles.timePicker, classes.inp)}
                          />
                        </div>
                        <span>-</span>
                        <div
                          className={styles.timePickerContent}
                          onClick={() => handleClickPicker(2)}
                        >
                          <DesktopTimePicker
                            timeSteps={{ hours: 1, minutes: 30, seconds: 5 }}
                            components={{ ActionBar: 'none' }}
                            open={openPicker && isActive === 2}
                            value={firstEndHour}
                            onChange={newValue => setFirstEndHour(newValue)}
                            onClose={handleClose}
                            className={cx(styles.timePicker, classes.inp)}
                          />
                        </div>
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>

                <div className={styles.occupationHourRow}>
                  <div className={styles.activateSecondOccupationHoursWrapper}>
                    <Typography className={styles.occupationHourTitle}>
                      {translate('addReportModal.secondOccupationHours')}
                    </Typography>
                    <Switch
                      checked={secondOccupationHoursChecked}
                      onChange={handleChangeSecondOccupationHoursChecked}
                      name='checkSecondOccupationHours'
                      color='primary'
                      inputProps={{ 'aria-label': 'controlled' }}
                      className={cx(styles.switch, classes.switch)}
                    />
                  </div>
                  <div className={styles.occupationHourCheckRow}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DemoContainer components={['TimePicker']}>
                        <div className={styles.secondOccupancyTime}>
                          <div
                            className={styles.timePickerContent}
                            onClick={() => handleClickPicker(3)}
                            disabled={!secondOccupationHoursChecked}
                          >
                            <DesktopTimePicker
                              timeSteps={{ hours: 1, minutes: 30, seconds: 5 }}
                              components={{ ActionBar: 'none' }}
                              open={openPicker && isActive === 3}
                              value={secondStartHour}
                              onChange={newValue => setSecondStartHour(newValue)}
                              onClose={handleClose}
                              className={cx(styles.timePicker, classes.inp)}
                              disabled={!secondOccupationHoursChecked}
                            />
                          </div>
                          <span>-</span>
                          <div
                            className={styles.timePickerContent}
                            onClick={() => handleClickPicker(4)}
                            disabled={!secondOccupationHoursChecked}
                          >
                            <DesktopTimePicker
                              timeSteps={{ hours: 1, minutes: 30, seconds: 5 }}
                              components={{ ActionBar: 'none' }}
                              open={openPicker && isActive === 4}
                              onClose={handleClose}
                              value={secondEndHour}
                              onChange={newValue => setSecondEndHour(newValue)}
                              className={cx(styles.timePicker, classes.inp)}
                              disabled={!secondOccupationHoursChecked}
                            />
                          </div>
                        </div>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  {errors.fourDates && (
                    <div className={styles.error}>
                      <small>{translate('validation.timeRange')}</small>
                    </div>
                  )}
                </div>
                <div className={styles.errorWrapper}>{error()}</div>
                <div className={styles.buttonWrapper}>
                  <LoadingButton
                    fullWidth
                    variant='contained'
                    className={styles.buttonSiguiente3}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    loadingPosition='end'
                    onClick={onClickCreate}
                  >
                    {translate('saveChanges')}
                  </LoadingButton>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('back')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

EditParameterAlertModalForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.any,
  // system: PropTypes.instanceOf(Map),
  translate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: getUser(state)
})

export default withLocalize(connect(mapStateToProps)(EditParameterAlertModalForm))
