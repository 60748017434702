import DataTypes from 'constants/DataTypes'
import MeasurementUnits from 'constants/MeasurementUnits'

export const DefaultMeasurementUnits = {
  [DataTypes.TEMPERATURE]: MeasurementUnits.CELSIUS,
  [DataTypes.VOCS_INDEX]: MeasurementUnits.VOCS_INDEX,
  [DataTypes.VOCS]: MeasurementUnits.VOCS_PPB,
  [DataTypes.FORMALDEHYDE]: MeasurementUnits.FORMALDEHYDE_UG,
  [DataTypes.CO]: MeasurementUnits.CO_PPM,
  [DataTypes.O3]: MeasurementUnits.O3_PPB,
  [DataTypes.NO2]: MeasurementUnits.NO2_PPB
}

export const getDefaultMeasurementUnit = dataType => {
  return DefaultMeasurementUnits[dataType]
}
export const getCompleteMeasurementUnits = (measurementUnits = {}) => ({
  ...DefaultMeasurementUnits,
  ...measurementUnits
})
