// -------------------- External imports ---------------------- //
import { useState, useEffect } from 'react'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import cx from 'classnames'
import _ from 'lodash'
import { useForm } from 'react-hook-form'

// -------------------- Material-UI imports ---------------------- //
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { blue } from '@material-ui/core/colors'
import { ArrowForward, ArrowBack, TrainOutlined } from '@material-ui/icons'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import {
  Radio,
  TextField,
  Typography,
  Button,
  InputAdornment,
  FormControl,
  Switch,
  ListItem,
  FormControlLabel,
  Checkbox,
  Collapse,
  ListItemText,
  RadioGroup,
  Tooltip,
  IconButton
} from '@material-ui/core'
import { StylesProvider, makeStyles } from '@material-ui/styles'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

// -------------------- Internal imports ---------------------- //
import styles from './AddFullScreenViewForm.module.scss'
import * as SystemUtils from 'utils/system'
import DataTypes from 'constants/DataTypes'
import MicaTypes from 'constants/MicaTypes'
import { isIndicator } from 'utils/data'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  switch: {
    '& .Mui-checked': {
      color: blue[600]
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: blue[600],
      '&:hover': {
        // backgroundColor: blue[50],
        // backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity)
      }
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blue[600],
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
      backgroundColor: blue[100]
    },
    '& .MuiSwitch-thumb': {
      transform: 'translateY(-2px)'
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  }
}))

const AddFullScreenViewForm = props => {
  const classes = useStyles()
  const { onSubmit, translate, systems, isSubmitting, user } = props

  // State variable for changing between form pages
  const [activeTab, setActiveTab] = useState(0)

  // Aux variables for system selected in Page 1
  const [sortedDataState, setSortedDataState] = useState([])
  const [systemState, setSystemState] = useState({})

  // Page 1
  const [open, setOpen] = useState({})
  const [filterExpression, setFilterExpression] = useState('')
  const [fullScreenName, setFullScreenName] = useState('')

  // Page 2
  const [indicatorSelected, setIndicatorSelected] = useState('')
  const [openInfo, setOpenInfo] = useState({})
  const [infoTab, setInfoTab] = useState('')

  // Page 3
  const [checkAllIsClicked, setCheckAllIsClicked] = useState(false)
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [typeChecked, setTypeChecked] = useState([])

  // Page 4
  const [checkedSwitch, setCheckedSwitch] = useState(true)
  const [checkedLogoSwitch, setCheckedLogoSwitch] = useState(false)

  const [type, setType] = useState([])

  const typeArray = []
  const { handleSubmit, register, errors, getValues, setError, clearErrors } = useForm()

  const handleChange = event => {
    if (event.target.type === 'radio') {
      clearErrors('selectedDevices')
    }
    if (event.target.type === 'checkbox') {
      const { value, checked } = event.target
      setTypeChecked([...typeChecked, value])
      if (!checked) {
        setTypeChecked(typeChecked.filter(item => item !== value))
      }
      let numberOfParams = 0
      type.map((t, i) => {
        if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0) {
          // Hay seleccionados
          numberOfParams++
        }
        return null
      })
      if (numberOfParams === 0) {
        setError('types', { shouldFocus: 'types' })
      } else if (numberOfParams > 12) {
        setError('tooManyParams', { shouldFocus: 'tooManyParams' })
      } else {
        clearErrors('types')
        clearErrors('tooManyParams')
      }
    }
  }

  const handleChangeIndicator = event => {
    setIndicatorSelected(event.target.value)
  }

  const handleInfo = keyInfo => {
    const openDataType = openInfo[keyInfo]
    setOpenInfo({ ...openInfo, [keyInfo]: !openDataType })
    if (infoTab === keyInfo) {
      setInfoTab('')
    } else {
      setInfoTab(keyInfo)
    }
  }

  const getTypeInfo = dataType => {
    switch (dataType) {
      case DataTypes.COVID_19:
        return 'micaLiteInfo'
      case DataTypes.VENTILATION_INDICATOR:
        return 'micaMiniInfo'
      default:
        return 'micaInfo'
    }
  }

  const getTypeInfoLink = dataType => {
    switch (dataType) {
      case DataTypes.COVID_19:
        return 'https://inbiot.es/wikinbiot/indicador-resistencia-virus'
      case DataTypes.VENTILATION_INDICATOR:
        return 'https://www.inbiot.es/wikinbiot/indicador-eficacia-ventilacion'
      case DataTypes.THERMAL_INDICATOR:
        return 'https://inbiot.es/wikinbiot/indicadore-confort-termohigrometrico'
      default:
        return 'https://inbiot.es/wikinbiot/indicador-calidad-de-aire-interior'
    }
  }

  const handleSelectAll = event => {
    setIsCheckAll(!isCheckAll)
    setTypeChecked(type.filter(t => !isIndicator(t)))
    setCheckAllIsClicked(true)
    if (isCheckAll) {
      setTypeChecked([])
    } else {
      clearErrors('types')
    }
  }

  const handleScreenNameChange = event => {
    setFullScreenName(event.target.value)
    if (event.target.value !== '') {
      clearErrors('screenViewName')
    } else {
      setError('screenViewName', { shouldFocus: 'screenViewName' })
    }
  }

  const handleChangeSwitch = event => {
    setCheckedSwitch(event.target.checked)
  }

  const handleChangeLogoSwitch = event => {
    setCheckedLogoSwitch(event.target.checked)
  }

  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }

  const onClickCancel = () => {
    props.hideModal()
  }

  const checkInputs = () => {
    if (getValues('screenViewName') !== undefined) {
      typeArray.screenName = getValues('screenViewName')
    }
    if (getValues('selectedDevices') !== undefined) {
      typeArray.selectedDevice = getValues('selectedDevices')
    }
    type.map((t, i) => {
      typeArray[t] = !!(getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0)
      return null
    })
    typeArray.checkedSwitch = checkedSwitch
    typeArray.showLogo = checkedLogoSwitch
    typeArray.indicator = indicatorSelected
    setSystemState(typeArray)
    onSubmit(typeArray)
  }

  const onClickNext = () => {
    if (activeTab === 0) {
      if (
        getValues('screenViewName') !== '' &&
        getValues('selectedDevices') !== null &&
        getValues('selectedDevices') !== ''
      ) {
        // Si ambos campos tienen valor paso de pantalla
        const system = systems.filter(s => {
          return s._id === getValues('selectedDevices')
        })[0]
        const sortedData = SystemUtils.getDataSorted(system)
        setSortedDataState(sortedData)
        setSystemState(system)
        setIndicatorSelected(getSystemMainIndicator(system))
        setActiveTab(1)
      }
      if (getValues('screenViewName') === '') {
        setError('screenViewName', { shouldFocus: 'screenViewName' })
      }
      if (getValues('selectedDevices') === null || getValues('selectedDevices') === '') {
        setError('selectedDevices', { shouldFocus: 'selectedDevices' })
      }
    } else if (activeTab === 1) {
      setActiveTab(2)
    } else {
      let numberOfParams = 0
      type.map((t, i) => {
        if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0) {
          // Hay seleccionados
          numberOfParams++
        }
        return null
      })
      if (numberOfParams !== 0) {
        setActiveTab(3)
      } else {
        setError('types', { shouldFocus: 'types' })
      }
    }
  }

  const onClickBack = () => {
    if (activeTab === 3) {
      setActiveTab(2)
      clearErrors('types')
    } else if (activeTab === 2) {
      setActiveTab(1)
    } else {
      setActiveTab(0)
    }
  }

  const onClickCreate = () => {
    checkInputs()
  }

  useEffect(() => {
    const types = []
    sortedDataState.map(d => {
      types.push(d.type)
      return null
    })
    setType(types)
  }, [sortedDataState, systemState])

  const isIndeterminate = () => {
    return checkAllIsClicked && typeChecked.length > 0 && typeChecked.length !== type.length
  }

  const getSystemMainIndicator = system => {
    switch (system.micaType) {
      case MicaTypes.MICA_LITE:
        return DataTypes.COVID_19
      case MicaTypes.MICA_MINI:
        return DataTypes.VENTILATION_INDICATOR
      default:
        return DataTypes.IAQ
    }
  }

  const handleFilterExpressionChange = event => {
    setFilterExpression(event.target.value)
  }

  const checkNumberOfParams = () => {
    let numberOfParams = 0
    type.forEach(typeValue => {
      if (!isIndicator(typeValue)) {
        numberOfParams++
      }
    })
    if (numberOfParams <= 12) {
      return true
    }
    return false
  }

  const moreThan8Params = checkNumberOfParams()

  let systemsJS = []

  if (filterExpression !== '') {
    systemsJS = systems.filter(s => {
      return s.name.toLowerCase().indexOf(filterExpression.toLowerCase()) !== -1
    })
  } else {
    systemsJS = systems
  }

  const systemsByGroup = _.groupBy(systemsJS, 'group')
  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>
          {translate('addFullScreenModal.newShareable')}
        </Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(() => onSubmit(systemState))}
          >
            <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <div className={styles.firstPage}>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderSelected}>
                    {translate('addFullScreenModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addFullScreenModal.navIndicators')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addFullScreenModal.navParams')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addFullScreenModal.navPrivacy')}
                  </Typography>
                </div>
                <TextField
                  variant='outlined'
                  required
                  fullWidth
                  onChange={handleScreenNameChange}
                  label={translate('addFullScreenModal.screenViewName')}
                  id='screenViewName'
                  name='screenViewName'
                  error={errors.screenViewName}
                  placeholder={translate('addFullScreenModal.screenViewName')}
                  inputRef={register({ required: TrainOutlined })}
                  value={fullScreenName}
                  helperText={`${fullScreenName.length}/36`}
                  inputProps={{ maxLength: 36 }}
                  className={cx(styles.input, classes.input, {
                    [styles.hasError]: errors.screenViewName
                  })}
                />
                <div className={styles.wrapperError}>
                  {errors.screenViewName && (
                    <div className={styles.error}>
                      <small>{translate('validation.required')}</small>
                    </div>
                  )}
                </div>
                <TextField
                  variant='outlined'
                  fullWidth
                  type='search'
                  onChange={handleFilterExpressionChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                  id='screenViewName'
                  name='screenViewName'
                  placeholder={translate('searchDevice')}
                  className={cx(styles.input, classes.input, {
                    [styles.hasError]: errors.screenViewName
                  })}
                />
                <div>
                  <Typography className={styles.subInfo}>
                    {translate('addFullScreenModal.selectDevice')}
                  </Typography>
                </div>
                <Paper
                  className={styles.devicesListPaper}
                  classes={{ root: classes.paper }}
                >
                  <List
                    disableTouchRipple
                    className={styles.devicesList}
                  >
                    <FormControl component='fieldset'>
                      <RadioGroup onChange={handleChange}>
                        {_.map(systemsByGroup, function (systems, group) {
                          systems = _.groupBy(systems, 'micaType')
                          return (
                            <div className={styles.noGroupWrapper}>
                              {group !== 'undefined' && (
                                <div>
                                  <ListItem
                                    button
                                    disableTouchRipple
                                    className={styles.groupListItem}
                                  >
                                    {!open[group] ? (
                                      <ExpandLess
                                        onClick={() => handleClick(group)}
                                        className={styles.expandIcon}
                                      />
                                    ) : (
                                      <ExpandMore
                                        onClick={() => handleClick(group)}
                                        className={styles.expandIcon}
                                      />
                                    )}
                                    <ListItemText
                                      primary={group}
                                      className={styles.listItemText}
                                    />
                                  </ListItem>
                                </div>
                              )}
                              {group === 'undefined' && (
                                /* Desplegamos Por Tipos, micas sin Grupo definido */
                                <div>
                                  {_.map(systems, function (systems, type) {
                                    return (
                                      <div className={styles.typeRowWrapper}>
                                        <ListItem
                                          button
                                          disableTouchRipple
                                          className={styles.typeListItem}
                                        >
                                          {!open[type] ? (
                                            <ExpandLess
                                              onClick={() => handleClick(type)}
                                              className={styles.expandIcon}
                                            />
                                          ) : (
                                            <ExpandMore
                                              onClick={() => handleClick(type)}
                                              className={styles.expandIcon}
                                            />
                                          )}
                                          <ListItemText
                                            primary={translate(type)}
                                            className={styles.micaTypeItemText}
                                          />
                                        </ListItem>
                                        <Collapse
                                          in={!open[type]}
                                          timeout='auto'
                                          unmountOnExit
                                        >
                                          {systems.map((system, index) => {
                                            return (
                                              <div
                                                key={'MicaItem' + index}
                                                className={styles.micaItem}
                                              >
                                                <FormControlLabel
                                                  onKeyPress={e => {
                                                    e.key === 'Enter' && e.preventDefault()
                                                  }}
                                                  className={styles.checkboxForm}
                                                  value={system._id}
                                                  control={
                                                    <Radio
                                                      className={cx(styles.checkbox)}
                                                      classes={{
                                                        checked: styles.checkedBox
                                                      }}
                                                      inputRef={register()}
                                                      disableRipple
                                                      name={'selectedDevices'}
                                                      value={system._id}
                                                    />
                                                  }
                                                  label={system.name}
                                                />
                                              </div>
                                            )
                                          })}
                                        </Collapse>
                                      </div>
                                    )
                                  })}
                                </div>
                              )}
                              {group !== 'undefined' && (
                                <Collapse
                                  in={!open[group]}
                                  timeout='auto'
                                  unmountOnExit
                                  classes={{
                                    wrapper: classes.wrapper
                                  }}
                                >
                                  {_.map(systems, function (systems, type) {
                                    return (
                                      <div className={styles.typeRowWrapper}>
                                        <ListItem
                                          button
                                          disableTouchRipple
                                          className={styles.typeListItem}
                                        >
                                          {!open[type] ? (
                                            <ExpandLess
                                              onClick={() => handleClick(type)}
                                              className={styles.expandIcon}
                                            />
                                          ) : (
                                            <ExpandMore
                                              onClick={() => handleClick(type)}
                                              className={styles.expandIcon}
                                            />
                                          )}
                                          <ListItemText
                                            primary={translate(type)}
                                            className={styles.micaTypeItemText}
                                          />
                                        </ListItem>
                                        <Collapse
                                          in={!open[type]}
                                          timeout='auto'
                                          unmountOnExit
                                        >
                                          {systems.map((system, index) => {
                                            return (
                                              <div
                                                key={'AnothermIcaItem' + index}
                                                className={styles.micaItem}
                                              >
                                                <FormControlLabel
                                                  onKeyPress={e => {
                                                    e.key === 'Enter' && e.preventDefault()
                                                  }}
                                                  className={styles.checkboxForm}
                                                  value={system._id}
                                                  control={
                                                    <Radio
                                                      className={cx(
                                                        styles.checkbox,
                                                        styles.radioItem
                                                      )}
                                                      classes={{
                                                        checked: styles.checkedBox
                                                      }}
                                                      inputRef={register()}
                                                      name={'selectedDevices'}
                                                      value={system._id}
                                                    />
                                                  }
                                                  label={system.name}
                                                />
                                              </div>
                                            )
                                          })}
                                        </Collapse>
                                      </div>
                                    )
                                  })}
                                </Collapse>
                              )}
                            </div>
                          )
                        })}
                      </RadioGroup>
                    </FormControl>
                  </List>
                </Paper>
                <div className={styles.wrapperError}>
                  {errors.selectedDevices && (
                    <div className={styles.error}>
                      <small>{translate('validation.anyDeviceRequired')}</small>
                    </div>
                  )}
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonSiguiente1}
                    onClick={onClickNext}
                  >
                    {translate('next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheader}>
                    {translate('addFullScreenModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderSelected}>
                    {translate('addFullScreenModal.navIndicators')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addFullScreenModal.navParams')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addFullScreenModal.navPrivacy')}
                  </Typography>
                </div>
                <div className={styles.indicatorsPageWrapper}>
                  <div>
                    <Typography className={styles.subInfo}>
                      {translate('addFullScreenModal.selectIndicator')}
                    </Typography>
                  </div>
                  <FormControl component='fieldset'>
                    <RadioGroup
                      className={styles.checkboxForm}
                      aria-label='indicator'
                      name='indicator'
                      value={indicatorSelected}
                      onChange={handleChangeIndicator}
                    >
                      {type.map((dataType, i) => {
                        if (!isIndicator(dataType)) return null
                        return (
                          <div
                            key={'indicator' + i}
                            className={styles.wrap}
                          >
                            <div className={styles.wrapperInfo}>
                              <FormControlLabel
                                className={styles.labelRadio}
                                value={dataType}
                                control={
                                  <Radio
                                    name='indicator'
                                    inputRef={register()}
                                    className={cx(styles.checkbox, styles.radioItem)}
                                    classes={{ checked: styles.checkedBox }}
                                  />
                                }
                                label={translate(dataType)}
                              />
                              <div className={styles.wrapperInfoButton}>
                                <IconButton
                                  className={styles.infoButton}
                                  variant='outlined'
                                  // onClick={setOpenInfo(dataType)}
                                  onClick={() => handleInfo(dataType)}
                                  value={getValues(`types[${i}]`)}
                                  name='infoParam'
                                  disableRipple
                                >
                                  <InfoOutlinedIcon />
                                </IconButton>
                              </div>
                            </div>
                            <div
                              style={{
                                display: infoTab === dataType ? 'block' : 'none'
                              }}
                              className={styles.caja}
                            >
                              {
                                <Translate
                                  id={getTypeInfo(dataType)}
                                  options={{ renderInnerHtml: true }}
                                />
                              }
                              <Button
                                fullWidth
                                disableRipple
                                variant='contained'
                                className={styles.buttonSiguiente}
                                onClick={e => {
                                  e.preventDefault()
                                  window.open(getTypeInfoLink(dataType), '_blank')
                                }}
                              >
                                <a
                                  href={getTypeInfoLink(dataType)}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                />
                                {translate('infoModal.moreInfo')}
                              </Button>
                            </div>
                          </div>
                        )
                      })}
                      <div className={styles.wrap}>
                        <div className={styles.wrapperInfo}>
                          <FormControlLabel
                            className={styles.labelRadio}
                            value='none'
                            control={
                              <Radio
                                name='indicator'
                                inputRef={register()}
                                className={cx(styles.checkbox, styles.radioItem)}
                                classes={{ checked: styles.checkedBox }}
                              />
                            }
                            label={translate('addFullScreenModal.noIndicator')}
                          />
                        </div>
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <div
                    className={styles.buttonWrapper}
                    style={{ marginTop: type.length < 9 ? '60.5%' : '37.5%' }}
                  >
                    <Button
                      fullWidth
                      disableRipple
                      variant='contained'
                      className={styles.buttonSiguiente2}
                      onClick={onClickNext}
                    >
                      {translate('next')}
                      <ArrowForward className={styles.arrow} />
                    </Button>
                  </div>
                  <div className={styles.buttonWrapper}>
                    <Button
                      fullWidth
                      disableRipple
                      variant='contained'
                      className={styles.buttonCancelar}
                      onClick={onClickBack}
                    >
                      <ArrowBack className={styles.arrow} />
                      {translate('back')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 2 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheader}>
                    {translate('addFullScreenModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addFullScreenModal.navIndicators')}
                  </Typography>
                  <Typography className={styles.subheaderSelected}>
                    {translate('addFullScreenModal.navParams')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addFullScreenModal.navPrivacy')}
                  </Typography>
                </div>
                <div>
                  <Typography className={styles.subInfo}>
                    {translate('addFullScreenModal.selectParameters')}
                  </Typography>
                </div>
                {moreThan8Params && (
                  <FormControlLabel
                    className={styles.selectAllCheckboxForm}
                    control={
                      <Checkbox
                        className={cx(styles.checkbox)}
                        classes={{ checked: styles.checkedBox }}
                        name='selectAll'
                        id='selectAll'
                        indeterminate={isIndeterminate()}
                        onChange={handleSelectAll}
                        isChecked={isCheckAll}
                      />
                    }
                    label={translate('addFullScreenModal.selectAll')}
                  />
                )}
                <div className={styles.checkboxWrapper}>
                  {type.map((dataType, i) => {
                    if (isIndicator(dataType)) return null
                    return (
                      <div
                        key={'param' + i}
                        className={styles.checkboxFormDiv}
                      >
                        <FormControlLabel
                          className={styles.checkboxForm}
                          control={
                            <Checkbox
                              className={cx(styles.checkbox)}
                              classes={{ checked: styles.checkedBox }}
                              onChange={handleChange}
                              checked={typeChecked.includes(dataType)}
                              required
                              name={`types[${i}]`}
                              value={dataType}
                              inputRef={register()}
                              disableRipple
                            />
                          }
                          label={translate(dataType)}
                        />
                      </div>
                    )
                  })}
                </div>
                <div className={styles.wrapperError}>
                  {errors.types && (
                    <div className={styles.error}>
                      <small>{translate('validation.anyFieldRequired')}</small>
                    </div>
                  )}
                  {errors.tooManyParams && (
                    <div className={styles.error}>
                      <small>{translate('validation.tooManyParams')}</small>
                    </div>
                  )}
                </div>
                <div
                  className={styles.buttonWrapper}
                  style={{ marginTop: type.length < 9 ? '60.5%' : '37.5%' }}
                >
                  <Button
                    fullWidth
                    disableRipple
                    disabled={errors.tooManyParams || errors.types}
                    variant='contained'
                    className={styles.buttonSiguiente2}
                    onClick={onClickNext}
                  >
                    {translate('next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('back')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 3 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheader}>
                    {translate('addFullScreenModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addFullScreenModal.navIndicators')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addFullScreenModal.navParams')}
                  </Typography>
                  <Typography className={styles.subheaderSelected}>
                    {translate('addFullScreenModal.navPrivacy')}
                  </Typography>
                </div>
                <div className={styles.visualizeQRWrapper}>
                  <Typography className={styles.visualizeQR}>
                    {translate('addFullScreenModal.infoLabelRadio')}
                  </Typography>
                  <Switch
                    checked={checkedSwitch}
                    onChange={handleChangeSwitch}
                    name='checked'
                    color='primary'
                    inputProps={{ 'aria-label': 'controlled' }}
                    className={cx(styles.switch, classes.switch)}
                  />
                </div>
                <div className={styles.infoPublicWrapper}>
                  <div className={styles.iconPublic}>
                    <InfoOutlinedIcon />
                  </div>
                  <Typography>{translate('addFullScreenModal.infoPublic')}</Typography>
                </div>
                <div className={styles.visualizeLogoWrapper}>
                  <Typography className={styles.visualizeLogo}>
                    {translate('addFullScreenModal.infoShowLogo')}
                  </Typography>
                  <Switch
                    checked={checkedLogoSwitch}
                    disabled={!user.toJS().isPremium}
                    onChange={handleChangeLogoSwitch}
                    name='checkedLogo'
                    color='primary'
                    inputProps={{ 'aria-label': 'controlled' }}
                    className={cx(styles.switch, classes.switch)}
                  />
                </div>
                {!user.toJS().isPremium && (
                  <Tooltip
                    title={translate('businessPlan')}
                    placement='left'
                    arrow='true'
                  >
                    <div className={styles.getMoreRange}>
                      {translate('addFullScreenModal.howToShowLogo')}
                    </div>
                  </Tooltip>
                )}
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonSiguiente3}
                    disabled={isSubmitting}
                    onClick={onClickCreate}
                  >
                    {translate('addFullScreenModal.createShareable')}
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonCancelar}
                    disabled={isSubmitting}
                    onClick={onClickBack}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('back')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

export default withLocalize(connect()(AddFullScreenViewForm))
