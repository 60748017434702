import { StylesProvider } from '@material-ui/styles'
import styles from './MultipleKeysCreated.module.scss'
import { useState } from 'react'

export const MultipleKeysCreated = ({ translate, handleSubmit, newApiSystems, hideModal }) => {
  const [downloadFileName, setDownloadFileName] = useState('')

  const onDownloadClick = () => {
    handleSubmit(downloadFileName, newApiSystems)
  }

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <span className={styles.header}>{translate('integrationsPage.apiKeysCreated')}</span>
        <div className={styles.modalContent}>
          <div className={styles.modalText}>
            <span>{translate('integrationsPage.apiKeysDonwloadText')}</span>
          </div>

          <div className={styles.fileNameInput}>
            <input
              type='text'
              placeholder={translate('fileName')}
              onChange={e => setDownloadFileName(e.target.value)}
              value={downloadFileName}
            />
          </div>
        </div>

        <div className={styles.modalFooter}>
          <button
            onClick={() => onDownloadClick()}
            className={styles.downloadButton}
          >
            {translate('downloadFile').toUpperCase()}
          </button>
          <button
            className={styles.closeButton}
            onClick={hideModal}
          >
            {translate('close').toUpperCase()}
          </button>
        </div>
      </div>
    </StylesProvider>
  )
}
