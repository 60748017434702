export const downloadApiKeysCsv = (fileName, downloadDevices) => {
  // Construir los encabezados del CSV
  const headers = ['Device Name, API Key, systemId']
  // Mapear los datos de los dispositivos en formato CSV
  const rows = downloadDevices.map(device => `${device.name},${device.apiKey},${device._id}`)
  // Combinar encabezados y filas
  const csvContent = [headers, ...rows].join('\n')

  // Crear un archivo Blob con el contenido del CSV
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

  // Crear un enlace temporal para descargar el archivo
  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)
  link.href = url
  link.setAttribute('download', fileName + '.csv')
  document.body.appendChild(link)
  link.click()

  // Limpiar el DOM
  document.body.removeChild(link)
}
