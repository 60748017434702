// --------------- External imports -------------- //
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import _ from 'lodash'

// --------------- Material-UI imports -------------- //
import { Modal } from '@material-ui/core'

// --------------- Internal imports -------------- //
import { getUser } from 'common/selectors/user'
import { getError, getIsSubmitting } from '../selectors'
import { updateAlarm } from 'common/actions/systems'
import { resetError } from 'common/actions/error'
import { hideModal } from 'common/actions/modal'
import EditParameterAlertModalForm from '../components/EditParameterAlertModalForm/EditParameterAlertModalForm'
import { addAlertRecipient, deleteAlertRecipient } from 'common/actions/user'
import styles from './EditParameterAlertModalContainer.module.scss'
import ConfirmationModal from 'modules/modals/ConfirmationModal'
import DataTypes from 'constants/DataTypes'
import * as DataUtils from 'utils/data'
import { getCompleteMeasurementUnits } from 'utils/dataUnitsDefault'

const EditParameterAlertModalContainer = props => {
  const [open] = useState(true)
  const [confirmingDeleteEmail, setConfirmingDeleteEmail] = useState(null)
  const [confirmingDelete, setConfirmingDelete] = useState(false)

  const {
    hideModal,
    updateAlarm,
    resetError,
    systems,
    alarm,
    user,
    deleteAlertRecipient,
    translate
  } = props
  const systemsByGroup = _.groupBy(systems, 'group')

  const getNormalizedValuesForType = (value, type) => {
    const measurementUnits = getCompleteMeasurementUnits(user.toJS().measurementUnits)
    if (type === 'tempLow' || type === 'tempHigh') type = DataTypes.TEMPERATURE
    let measurementUnit = measurementUnits[type]
    if (type === DataTypes.FORMALDEHYDE) {
      return Number(DataUtils.normalizeFormaldehyde(value, measurementUnit)).toFixed(0)
    } else if (type === DataTypes.VOCS) {
      return Number(DataUtils.normalizeTvoc(value, measurementUnit)).toFixed(0)
    } else if (type === DataTypes.VOCS_INDEX) {
      return Number(DataUtils.normalizeTvocIndex(value, measurementUnit)).toFixed(0)
    } else if (type === DataTypes.O3) {
      return Number(DataUtils.normalizeO3(value, measurementUnit)).toFixed(0)
    } else if (type === DataTypes.CO) {
      return Number(DataUtils.normalizeCO(value, measurementUnit)).toFixed(0)
    } else if (type === DataTypes.NO2) {
      return Number(DataUtils.normalizeNo2(value, measurementUnit)).toFixed(0)
    } else if (type === DataTypes.TEMPERATURE) {
      return Number(DataUtils.normalizeTemperature(value, measurementUnit)).toFixed(1)
    } else {
      return value
    }
  }

  const handleSubmit = async data => {
    const typesSelected = data.types.filter(x => !!x)
    const emailsSelected = data.emailRecipients
    const jsonData = {}
    const Meta = {}
    const emails = []
    for (let i = 0; i < typesSelected.length; i++) {
      Meta[typesSelected[i]] = getNormalizedValuesForType(
        data.typesInput[typesSelected[i]],
        typesSelected[i]
      )
    }
    for (let i = 0; i < emailsSelected.length; i++) {
      emails[i] = emailsSelected[i].toLowerCase()
    }
    jsonData.Meta = Meta
    jsonData.name = data.alarmName
    jsonData.alarmParams = data.alarmParams
    jsonData.emailRecipients = emails
    jsonData.active = true
    jsonData.alarmMailHours = {}
    jsonData.alarmMailHours.period1 = data.period1

    if (data.period2) {
      jsonData.alarmMailHours.period2 = data.period2
    }
    if (data.auxPeriod) {
      jsonData.alarmMailHours.auxPeriod = data.auxPeriod
      jsonData.alarmMailHours.auxDays = data.auxDays
    }
    if (data.auxPeriod2) {
      jsonData.alarmMailHours.auxPeriod2 = data.auxPeriod2
    }
    jsonData.selectedDevices = data.selectedDevices
    jsonData.alarmMailHours.days = data.occupancyDays

    const actionData = {
      AlarmId: alarm.AlarmId,
      jsonData
    }
    updateAlarm(actionData)
  }

  useEffect(() => {
    resetError()
  }, [resetError])

  const handleDeleteAlertRecipient = email => {
    setConfirmingDeleteEmail(email)
    setConfirmingDelete(true)
  }

  const confirmDeleteAlertRecipient = () => {
    deleteAlertRecipient({
      email: confirmingDeleteEmail,
      userId: user.toJS()._id
    })
    setConfirmingDelete(false)
    // hideModal()
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.content}>
          <EditParameterAlertModalForm
            {...props}
            systems={systems}
            systemsByGroup={systemsByGroup}
            hideModal={hideModal}
            onSubmit={handleSubmit}
            onDeleteAlertRecipient={handleDeleteAlertRecipient}
          />
          {confirmingDelete && (
            <ConfirmationModal
              hideModal={() => setConfirmingDelete(false)}
              translate={translate}
              onConfirm={confirmDeleteAlertRecipient}
              contentText={translate('deleteAlertRecipientConfirmationText')}
              contentDetail={confirmingDeleteEmail}
            />
          )}
        </div>
      </Modal>
    </div>
  )
}

EditParameterAlertModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}

const mapStateToProps = state => ({
  user: getUser(state),
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  updateAlarm,
  resetError,
  hideModal,
  deleteAlertRecipient,
  addAlertRecipient
}

export default withLocalize(
  connect(mapStateToProps, mapActionsToProps)(EditParameterAlertModalContainer)
)
