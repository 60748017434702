import { useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import { Modal } from '@material-ui/core'
import styles from './NewApiKeyContainer.module.scss'
import { showSnackbar } from '../../../../common/actions/snackbar'
import { connect } from 'react-redux'
import { createApiKey } from '../../../../common/actions/systems'

import NewApiKeyForm from '../components/NewApiKeyForm'
import { getIsSubmitting } from '../selectors'

const NewApiKeyContainer = props => {
  const [open] = useState(true)

  const { hideModal, user, createApiKey, isSubmitting, systems } = props
  const { isPremium } = user.toJS()
  const handleSubmit = data => {
    createApiKey(data)
  }

  const modalSystems = systems.filter(
    system => system.apiKey === undefined || system.apiKey === '' || system.apiKey === null
  )

  const numSystemsApiKey = systems.length - modalSystems.length

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <NewApiKeyForm
          {...props}
          isPremium={isPremium}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          systems={modalSystems}
          numSystemsApiKey={numSystemsApiKey}
        />
      </div>
    </Modal>
  )
}

NewApiKeyContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}
const mapStateToProps = state => ({
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  createApiKey,
  showSnackbar
}

export default withLocalize(connect(mapStateToProps, mapActionsToProps)(NewApiKeyContainer))
