import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'

import { StylesProvider } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import styles from './AvailableKeysForm.module.scss'

const AvailableKeysForm = props => {
  const { translate, apiLimitKeys, hideModal } = props

  const onClickCancel = () => {
    hideModal()
  }

  const openContactSupport = () => {
    window.location.href = 'mailto:inbiotservices@inbiot.es'
  }

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>
          {translate('integrationsPage.availableKeys')}
        </Typography>
        <div className={styles.modal}>
          <div className={styles.page}>
            <div className={styles.modalContent}>
              <div>
                <span>{translate('integrationsPage.availableKeysModalText.p1')}</span>
                <span className={styles.key}>{' ' + apiLimitKeys + ' '}</span>
                <span>{translate('integrationsPage.availableKeysModalText.p2')}</span>
              </div>
            </div>
            <div>
              <div className={styles.buttonWrapper}>
                <button
                  className={styles.buttonSiguiente}
                  onClick={openContactSupport}
                >
                  {translate('helpOptions.contactUs').toUpperCase()}
                </button>
                <button
                  className={styles.buttonCancelar}
                  onClick={onClickCancel}
                >
                  {translate('cancel').toUpperCase()}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StylesProvider>
  )
}

AvailableKeysForm.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(AvailableKeysForm)
