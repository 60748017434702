import { useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import cx from 'classnames'
import { useForm } from 'react-hook-form'
import _ from 'lodash'

import { makeStyles, StylesProvider } from '@material-ui/core/styles'
import {
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  InputAdornment,
  ListItem,
  ListItemText,
  Collapse
} from '@material-ui/core'

import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { blue } from '@material-ui/core/colors'
import styles from './NewApiKeyForm.module.scss'
import { LittleLoadingComponent } from 'common/components/LoadingComponent/LoadingComponent'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  }
}))

const NewApiKeyForm = props => {
  const { onSubmit, translate, systems, isSubmitting, user, numSystemsApiKey } = props
  const { apiServiceLimit } = user.toJS()

  const formatedApiLimit = apiServiceLimit || 0

  const classes = useStyles()

  const { handleSubmit, register, errors, clearErrors, setError } = useForm()

  const [open, setOpen] = useState({})
  const [filterExpression, setFilterExpression] = useState('')
  const [selectedDevices, setSelectedDevices] = useState([])
  const [isCheckAllDevices, setIsCheckAllDevices] = useState(false)

  const [checkAllDevicesIsClicked, setCheckDevicesAllIsClicked] = useState(false)

  const onClickCancel = () => {
    props.hideModal()
  }

  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }

  const handleSelectAllDevices = event => {
    setIsCheckAllDevices(!isCheckAllDevices)
    setCheckDevicesAllIsClicked(true)
    if (isCheckAllDevices) {
      setSelectedDevices([])
    } else {
      const systemIds = []
      systems.map(s => {
        if (systemIds.length + numSystemsApiKey < formatedApiLimit) systemIds.push(s._id)
        return null
      })
      setSelectedDevices(systemIds)
      clearErrors('selectedDevices')
      clearErrors('maxApiLimit')
    }
  }

  const handleSelectAllGroupDevices = (event, systems) => {
    if (event.target.checked) {
      const aux = []
      for (let i = 0; i < systems.length; i++) {
        if (
          !selectedDevices.includes(systems[i]._id) &&
          aux.length + selectedDevices.length + numSystemsApiKey < formatedApiLimit
        )
          aux.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices => [...selectedDevices, ...aux])
      clearErrors('selectedDevices')
      clearErrors('maxApiLimit')
    } else {
      const systemIds = []
      for (let i = 0; i < systems.length; i++) {
        if (systemIds.length + selectedDevices.length + numSystemsApiKey < formatedApiLimit)
          systemIds.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices.filter(s => !systemIds.includes(s)))
    }
  }

  const isIndeterminateDevices = () => {
    return (
      checkAllDevicesIsClicked &&
      selectedDevices.length > 0 &&
      selectedDevices.length !== systems.length
    )
  }

  const handleChangeDevice = event => {
    clearErrors('selectedDevices')
    clearErrors('maxApiLimit')
    const { checked, value } = event.target
    if (checked) {
      setSelectedDevices([...selectedDevices, value])
    } else {
      setSelectedDevices(selectedDevices.filter(item => item !== value))
    }
  }

  const handleFilterExpressionChange = event => {
    setFilterExpression(event.target.value)
  }

  const onClickDownload = () => {
    if (selectedDevices.length + numSystemsApiKey > formatedApiLimit) {
      setError('maxApiLimit', { type: 'maxDevices' })
    } else if (selectedDevices.length !== 0) {
      onSubmit(selectedDevices)
    } else {
      setError('selectedDevices', { type: 'required' })
    }
  }

  const isSystemDisabled = systemId => {
    if (selectedDevices.includes(systemId)) return false
    if (selectedDevices.length + numSystemsApiKey >= formatedApiLimit) return true
  }

  let systemsJS = []

  if (filterExpression !== '') {
    systemsJS = systems.filter(s => {
      return s.name.toLowerCase().indexOf(filterExpression.toLowerCase()) !== -1
    })
  } else {
    systemsJS = systems
  }
  const systemsGroup = _.groupBy(systemsJS, 'group')

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('integrationsPage.newApiKey')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div style={{ display: 'block' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderDevicesSelected}>
                    {translate('navDevices')}
                  </Typography>
                </div>
                <TextField
                  variant='outlined'
                  fullWidth
                  type='search'
                  onChange={handleFilterExpressionChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                  id='searchDevice'
                  name='searchDevice'
                  placeholder={translate('searchDevice')}
                  inputProps={{ maxLength: 32 }}
                  className={cx(classes.input, styles.input, {
                    [styles.hasError]: errors.alarmName
                  })}
                />
                <div className={styles.subInfoWrapper}>
                  <Typography className={styles.subInfo}>
                    {translate('downloadDataForm.selectDevices')}
                  </Typography>
                </div>
                <FormControlLabel
                  className={styles.selectAllCheckboxForm}
                  control={
                    <Checkbox
                      className={cx(styles.checkbox)}
                      classes={{ checked: styles.checkedBox }}
                      name='selectAllDevices'
                      id='selectAllDevices'
                      indeterminate={isIndeterminateDevices()}
                      onChange={handleSelectAllDevices}
                      checked={isCheckAllDevices}
                    />
                  }
                  label={translate('downloadDataForm.selectAll')}
                />
                <Paper
                  className={styles.devicesListPaper}
                  classes={{ root: classes.paper }}
                >
                  <List
                    disableTouchRipple
                    className={styles.devicesList}
                  >
                    {_.map(systemsGroup, function (systems, group) {
                      const systemsType = _.groupBy(systems, 'micaType')
                      return (
                        <div className={styles.noGroupWrapper}>
                          {group !== 'undefined' && (
                            <div>
                              <ListItem
                                button
                                disableTouchRipple
                                className={styles.groupListItem}
                              >
                                {!open[group] ? (
                                  <ExpandLess
                                    onClick={() => handleClick(group)}
                                    className={styles.expandIcon}
                                  />
                                ) : (
                                  <ExpandMore
                                    onClick={() => handleClick(group)}
                                    className={styles.expandIcon}
                                  />
                                )}
                                <Checkbox
                                  className={cx(styles.checkbox)}
                                  classes={{ checked: styles.checkedBox }}
                                  name={group}
                                  onChange={e => handleSelectAllGroupDevices(e, systems)}
                                  id={group}
                                />
                                <ListItemText
                                  primary={group}
                                  className={styles.listItemText}
                                />
                              </ListItem>
                            </div>
                          )}
                          {group === 'undefined' && (
                            <div>
                              {_.map(systemsType, function (systems, type) {
                                return (
                                  <div className={styles.typeRowWrapper}>
                                    <ListItem
                                      button
                                      disableTouchRipple
                                      className={styles.typeListItem}
                                    >
                                      {!open[type] ? (
                                        <ExpandLess
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      ) : (
                                        <ExpandMore
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      )}
                                      <ListItemText
                                        primary={type}
                                        className={styles.micaTypeItemText}
                                      />
                                    </ListItem>
                                    <Collapse
                                      in={!open[type]}
                                      timeout='auto'
                                      unmountOnExit
                                    >
                                      {systems.map((system, index) => {
                                        return (
                                          <div
                                            key={system._id}
                                            className={styles.micaItem}
                                          >
                                            <FormControlLabel
                                              className={styles.checkboxForm}
                                              control={
                                                <Checkbox
                                                  className={cx(styles.checkbox)}
                                                  classes={{
                                                    checked: styles.checkedBox
                                                  }}
                                                  checked={selectedDevices.includes(system._id)}
                                                  disabled={isSystemDisabled(system._id)}
                                                  onChange={handleChangeDevice}
                                                  required
                                                  outline='true'
                                                  inputRef={register()}
                                                  disableRipple
                                                  name={`selectedDevices[]`}
                                                  value={system._id}
                                                />
                                              }
                                              label={system.name}
                                            />
                                          </div>
                                        )
                                      })}
                                    </Collapse>
                                  </div>
                                )
                              })}
                            </div>
                          )}

                          {group !== 'undefined' && (
                            <Collapse
                              in={!open[group]}
                              timeout='auto'
                              unmountOnExit
                              classes={{
                                wrapper: classes.wrapper
                              }}
                            >
                              {_.map(systemsType, function (systems, type) {
                                return (
                                  <div className={styles.typeRowWrapper}>
                                    <ListItem
                                      button
                                      disableTouchRipple
                                      className={styles.typeListItem}
                                    >
                                      {!open[type] ? (
                                        <ExpandLess
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      ) : (
                                        <ExpandMore
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      )}
                                      <ListItemText
                                        primary={translate(type)}
                                        className={styles.micaTypeItemText}
                                      />
                                    </ListItem>
                                    <Collapse
                                      in={!open[type]}
                                      timeout='auto'
                                      unmountOnExit
                                    >
                                      {systems.map((system, index) => {
                                        return (
                                          <div
                                            key={index + 'systemFormControlLabelCheckbox'}
                                            className={styles.micaItem}
                                          >
                                            <FormControlLabel
                                              className={styles.checkboxForm}
                                              control={
                                                <Checkbox
                                                  className={cx(styles.checkbox)}
                                                  classes={{
                                                    checked: styles.checkedBox
                                                  }}
                                                  checked={selectedDevices.includes(system._id)}
                                                  disabled={isSystemDisabled(system._id)}
                                                  onChange={handleChangeDevice}
                                                  required
                                                  outline='true'
                                                  inputRef={register()}
                                                  disableRipple
                                                  name={`selectedDevices[]`}
                                                  value={system._id}
                                                />
                                              }
                                              label={system.name}
                                            />
                                          </div>
                                        )
                                      })}
                                    </Collapse>
                                  </div>
                                )
                              })}
                            </Collapse>
                          )}
                        </div>
                      )
                    })}
                  </List>
                </Paper>
                <span>
                  {translate('integrationsPage.availableKeys') + ': '}{' '}
                  {formatedApiLimit - numSystemsApiKey - selectedDevices.length}
                </span>
                {errors.selectedDevices && (
                  <div className={styles.error}>
                    <small>{translate('validation.anyDeviceRequired')}</small>
                  </div>
                )}
                {errors.maxApiLimit && (
                  <div className={styles.error}>
                    <small>
                      {translate('validation.apiLimitReached.p1') +
                        ' ' +
                        (formatedApiLimit - numSystemsApiKey) +
                        '. ' +
                        translate('validation.apiLimitReached.p2')}
                    </small>
                  </div>
                )}
              </div>
              <div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonSiguiente}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickDownload}
                  >
                    {translate('create')}
                    {isSubmitting ? (
                      <span className={styles.submitingIcon}>
                        <LittleLoadingComponent />
                      </span>
                    ) : null}
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonCancelar}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

NewApiKeyForm.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(NewApiKeyForm)
