import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import cx from 'classnames'
import { useForm, Controller } from 'react-hook-form'
import { StylesProvider } from '@material-ui/core/styles'
import {
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio,
  createMuiTheme,
  Tooltip
} from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import localeSpanish from 'date-fns/locale/es'
import localeEnglish from 'date-fns/locale/en-US'
import styles from './DownloadStatisticsForm.module.scss'
import moment from 'moment'
import { ArrowBack, ArrowForward } from '@material-ui/icons'
import { LoadingButton } from '@mui/lab'
import { ThemeProvider } from '@material-ui/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const customTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      dayDisabled: {
        textDecoration: 'line-through'
      }
    }
  }
})

const DownloadStatisticsForm = props => {
  const { onSubmit, translate, activeLanguage, system, sortedData, isPremium, isManager } = props
  const { handleSubmit, register, errors, control, getValues, setError, clearErrors, formState } =
    useForm()
  const { isSubmitting } = formState

  const [type, setType] = useState([])
  const [value, setValue] = useState('d')
  const [orderValue, setOrderValue] = useState('0')
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [dataState, setDataState] = useState({})
  const [typeChecked, setTypeChecked] = useState([])
  const [checkAllIsClicked, setCheckAllIsClicked] = useState(false)

  let typeArray = []

  const handleOrderChange = event => {
    setOrderValue(event.target.value)
  }

  const handleChange = event => {
    if (event.target.type === 'radio') {
      const item = event.target.value
      setValue(item)
    }
    if (event.target.type === 'checkbox') {
      const { value, checked } = event.target
      setTypeChecked([...typeChecked, value])
      if (!checked) {
        setTypeChecked(typeChecked.filter(item => item !== value))
      }
      let numberOfParams = 0
      type.map((t, i) => {
        if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0) {
          // Hay seleccionados
          numberOfParams++
        }
        return null
      })
      if (numberOfParams === 0) {
        setError('types', { shouldFocus: 'types' })
      } else {
        clearErrors('types')
      }
    }
  }

  const onClickCancel = () => {
    props.hideModal()
  }

  const handleSelectAll = event => {
    setIsCheckAll(!isCheckAll)
    setTypeChecked(type)
    setCheckAllIsClicked(true)
    if (isCheckAll) {
      setTypeChecked([])
    } else {
      clearErrors('types')
    }
  }

  const checkInputs = () => {
    if (moment(getValues('endDate')) < moment(getValues('startDate'))) return 'Invalid date'
    typeArray = []
    const data = {}
    type.map((t, i) => {
      if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0 && t !== 'weather')
        typeArray.push(t)
      if (t === 'weather') data.weather = getValues(`types[${i}]`).length > 0
      return null
    })
    data.params = typeArray
    data.endDate = getValues('endDate')
    data.startDate = getValues('startDate')
    data.freq = getValues('statisticsInterval')
    data.order = Number(getValues('order'))
    setDataState(data)
    // onSubmit(data)
  }

  let locale
  if (activeLanguage.code === 'es') locale = localeSpanish
  if (activeLanguage.code === 'en') locale = localeEnglish

  useEffect(() => {
    const types = []
    sortedData.map(d => {
      types.push(d.type)
      return null
    })
    setType(types)
  }, [sortedData, system])

  const isIndeterminate = () => {
    return checkAllIsClicked && typeChecked.length > 0 && typeChecked.length !== type.length
  }

  const onClickNext = () => {
    if (activeTab === 0) {
      let numberOfParams = 0
      type.map((t, i) => {
        if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0) {
          // Hay seleccionados
          numberOfParams++
        }
        return null
      })
      if (numberOfParams !== 0) {
        setActiveTab(1)
      } else {
        setError('types', { shouldFocus: 'types' })
      }
    }
  }

  const onClickBack = () => {
    setActiveTab(0)
  }

  const onClickDownload = () => {
    checkInputs()
  }

  const disableDates = date => {
    if (date > moment().subtract(1, 'days')) return true
  }

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('downloadStatistics')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(() => onSubmit(dataState))}
          >
            <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <div className={styles.firstPage}>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderParamsSelected}>
                    {translate('editNavParams')}
                  </Typography>
                  <Typography className={styles.subheaderDateandFormat}>
                    {translate('navDateAndFormat')}
                  </Typography>
                </div>
                <div>
                  <Typography className={styles.subInfo}>
                    {translate('downloadDataForm.selectParameters')}
                  </Typography>
                </div>
                <FormControlLabel
                  className={styles.selectAllCheckboxForm}
                  control={
                    <Checkbox
                      className={cx(styles.checkbox)}
                      classes={{ checked: styles.checkedBox }}
                      name='selectAll'
                      id='selectAll'
                      indeterminate={isIndeterminate()}
                      onChange={handleSelectAll}
                      isChecked={isCheckAll}
                    />
                  }
                  label={translate('downloadDataForm.selectAll')}
                />
                <div className={styles.checkboxWrapper}>
                  {type.map((dataType, i) => {
                    return (
                      <div
                        key={i + 'datatypeanothercheckbox'}
                        className={styles.checkboxFormDiv}
                      >
                        <FormControlLabel
                          className={styles.checkboxForm}
                          control={
                            <Checkbox
                              className={cx(styles.checkbox)}
                              classes={{ checked: styles.checkedBox }}
                              onChange={handleChange}
                              checked={typeChecked.includes(dataType)}
                              required
                              name={`types[${i}]`}
                              value={dataType}
                              inputRef={register()}
                              disableRipple
                            />
                          }
                          label={translate(dataType)}
                        />
                      </div>
                    )
                  })}
                </div>
                {errors.types && (
                  <div className={styles.error}>
                    <small>{translate('validation.anyFieldRequired')}</small>
                  </div>
                )}
                <div className={styles.infoPublicWrapper}>
                  <div className={styles.iconPublic}>
                    <InfoOutlinedIcon />
                  </div>
                  <Typography className={styles.downloadStatisticsInfoLabel}>
                    {translate('downloadStatisticsInfo')}
                  </Typography>
                </div>
                <div className={styles.buttonWrapper1}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonSiguiente}
                    onClick={onClickNext}
                  >
                    {translate('next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickCancel}
                  >
                    {translate('cancel')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderParams}>
                    {translate('editNavParams')}
                  </Typography>
                  <Typography className={styles.subheaderDateandFormatSelected}>
                    {translate('navDateAndFormat')}
                  </Typography>
                </div>
                <div>
                  <Typography className={styles.subInfo}>
                    {translate('downloadDataForm.selectDateInterval')}
                  </Typography>
                </div>
                {!(isPremium || isManager) && (
                  <Tooltip
                    title={translate('businessPlan')}
                    placement='left'
                    arrow='true'
                  >
                    <div className={styles.getMoreRange}>
                      {translate('downloadDataForm.getMoreRange')}
                    </div>
                  </Tooltip>
                )}
                <div className={styles.datePickerWrapper}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={locale}
                  >
                    <div className={styles.picker}>
                      <Typography
                        variant='subtitle2'
                        className={styles.subInfo}
                      >
                        {translate('startDate')}
                      </Typography>
                      <ThemeProvider theme={customTheme}>
                        <Controller
                          as={
                            <KeyboardDatePicker
                              InputProps={{ readOnly: true }}
                              disableToolbar
                              disableFuture
                              shouldDisableDate={disableDates}
                              variant='inline'
                              margin='0'
                              inputRef={register()}
                              autoOk
                              name='startDatePicker'
                              className={cx(styles.datePicker, {
                                [styles.hasError]: errors.startDate
                              })}
                              format='dd/MM/yyyy'
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                            />
                          }
                          control={control}
                          name='startDate'
                          defaultValue={moment().subtract(1, 'days')}
                          rules={{ required: true }}
                        />
                      </ThemeProvider>
                    </div>
                    {errors.startDate && (
                      <div className={styles.error}>
                        <small>{translate('validation.required')}</small>
                      </div>
                    )}
                    <div className={styles.picker}>
                      <Typography
                        variant='subtitle2'
                        className={styles.subInfo}
                      >
                        {translate('endDate')}
                      </Typography>
                      <ThemeProvider theme={customTheme}>
                        <Controller
                          as={
                            <KeyboardDatePicker
                              InputProps={{ readOnly: true }}
                              disableToolbar
                              disableFuture
                              variant='inline'
                              inputRef={register()}
                              format='dd/MM/yyyy'
                              autoOk
                              margin='0'
                              name='endDatePicker'
                              className={cx(styles.datePicker, {
                                [styles.hasError]: errors.endDate
                              })}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}
                            />
                          }
                          control={control}
                          name='endDate'
                          defaultValue={moment}
                          rules={{ required: true, validate: checkInputs }}
                        />
                      </ThemeProvider>
                    </div>
                    {errors.endDate && errors.endDate?.type === 'required' && (
                      <div className={styles.error}>
                        <small>{translate('validation.required')}</small>
                      </div>
                    )}
                    {errors.endDate && errors.endDate?.type === 'validate' && (
                      <div className={styles.error}>
                        <small>{translate('validation.invalidDateRange')}</small>
                      </div>
                    )}
                  </MuiPickersUtilsProvider>
                </div>
                <div>
                  <Typography className={styles.subInfo}>
                    {translate('downloadDataForm.statisticsInterval')}
                  </Typography>
                </div>
                <div className={styles.intervalGroup}>
                  <FormControl component='fieldset'>
                    <RadioGroup
                      className={styles.radioGroup}
                      aria-label='statisticsInterval'
                      name='statisticsInterval'
                      value={value}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        className={styles.labelRadio}
                        value='d'
                        control={
                          <Radio
                            inputRef={register()}
                            className={styles.radioItem}
                          />
                        }
                        label={translate('dailyStatistics')}
                      />
                      <FormControlLabel
                        className={styles.labelRadio}
                        value='w'
                        control={
                          <Radio
                            inputRef={register()}
                            className={styles.radioItem}
                          />
                        }
                        label={translate('weeklyStatistics')}
                      />
                      <FormControlLabel
                        className={styles.labelRadio}
                        value='m'
                        control={
                          <Radio
                            inputRef={register()}
                            className={styles.radioItem}
                          />
                        }
                        label={translate('monthlyStatistics')}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div>
                  <Typography className={styles.subInfo}>
                    {translate('downloadDataForm.downloadOrder')}
                  </Typography>
                </div>
                <div>
                  <FormControl component='fieldset'>
                    <RadioGroup
                      className={styles.radioGroup}
                      aria-label='order'
                      name='order'
                      value={orderValue}
                      onChange={handleOrderChange}
                    >
                      <FormControlLabel
                        className={styles.labelRadio}
                        value='0'
                        control={
                          <Radio
                            inputRef={register()}
                            className={styles.radioItem}
                          />
                        }
                        label={translate('downloadDataForm.olderDataFirst')}
                      />
                      <FormControlLabel
                        className={styles.labelRadio}
                        value='1'
                        control={
                          <Radio
                            inputRef={register()}
                            className={styles.radioItem}
                          />
                        }
                        label={translate('downloadDataForm.latestDataFirst')}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className={styles.buttonWrapper}>
                  <LoadingButton
                    type='submit'
                    fullWidth
                    loading={isSubmitting}
                    loadingPosition='end'
                    variant='contained'
                    className={styles.buttonSiguiente}
                    onClick={onClickDownload}
                  >
                    {translate('downloadStatistics')}
                  </LoadingButton>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disabled={isSubmitting}
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('back')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

DownloadStatisticsForm.propTypes = {
  system: PropTypes.instanceOf(Map),
  translate: PropTypes.func.isRequired
}

export default withLocalize(DownloadStatisticsForm)
