import DataUnits from 'constants/DataUnits'
import DataTypes from '../constants/DataTypes'
import MeasurementUnits from 'constants/MeasurementUnits'

export const getDataUnit = (type, measurementUnit) => {
  return measurementUnit ? DataUnits[measurementUnit] : DataUnits[type]
}

export const getFixValue = dataType => {
  if (
    dataType === DataTypes.TEMPERATURE ||
    dataType === DataTypes.RADIANT_TEMPERATURE ||
    dataType === DataTypes.CO
  ) {
    return 1
  }
  return 0
}

export const formatData = (value, type, measurementUnits, oldO3Units) => {
  if (value === 'p') return 'p'
  if (value === undefined) return null

  const toFixedValue = getFixValue(type)

  value = Number(value)

  if (type === DataTypes.TEMPERATURE && measurementUnits?.temperature) {
    value = convertTemperature(value, measurementUnits.temperature)
  }

  if (type === DataTypes.RADIANT_TEMPERATURE && measurementUnits?.radiantTemperature) {
    value = convertTemperature(value, measurementUnits.radiantTemperature)
  }

  if (type === DataTypes.VOCS_INDEX && measurementUnits?.vocsIndex) {
    value = convertTvocIndex(value, measurementUnits.vocsIndex)
  }

  if (type === DataTypes.VOCS && measurementUnits?.vocs) {
    value = convertTvoc(value, measurementUnits.vocs)
  }

  if (type === DataTypes.FORMALDEHYDE && measurementUnits?.formaldehyde) {
    value = convertFormaldehyde(value, measurementUnits.formaldehyde)
  }

  if (type === DataTypes.O3 && measurementUnits?.o3) {
    value = convertO3(value, measurementUnits.o3, oldO3Units)
  }

  if (type === DataTypes.CO && measurementUnits?.co) {
    value = convertCO(value, measurementUnits.co)
  }

  if (type === DataTypes.NO2 && measurementUnits?.no2) {
    value = convertNO2(value, measurementUnits.no2)
  }

  return Number(value.toFixed(toFixedValue))
}

// ---------------------------------- TEMPERATURE -------------------------------- //

const celsiusToFahrenheit = value => value * 1.8 + 32

const fahrenheitToCelsius = value => (value - 32) / 1.8

const celsiusToKelvin = value => value + 273.15

const kelvinToCelsius = value => value - 273.15

export const normalizeTemperature = (temperature, measurementUnit) => {
  if (!measurementUnit) return temperature

  if (measurementUnit === MeasurementUnits.KELVIN) {
    return kelvinToCelsius(temperature)
  }

  if (measurementUnit === MeasurementUnits.FAHRENHEIT) {
    return fahrenheitToCelsius(temperature)
  }

  return temperature
}

export const convertTemperature = (temperature, measurementUnit) => {
  if (measurementUnit === MeasurementUnits.CELSIUS) {
    return temperature
  }

  if (measurementUnit === MeasurementUnits.KELVIN) {
    return celsiusToKelvin(temperature)
  }

  if (measurementUnit === MeasurementUnits.FAHRENHEIT) {
    return celsiusToFahrenheit(temperature)
  }
}

// ---------------------------------- TVOCs -------------------------------- //

const indexToUgWell = value => (Math.log(501 - value) - 6.24) * -996.94

const ugWelltoIndex = value => 501 - Math.exp(value / -996.94 + 6.24)

const indexToUgReset = value => (Math.log(501 - value) - 6.24) * -878.53

const ugResetToIndex = value => 501 - Math.exp(value / -878.53 + 6.24)

const indexToPpb = value => (Math.log(501 - value) - 6.24) * -381.97

const ppbToIndex = value => 501 - Math.exp(value / -381.97 + 6.24)

export const normalizeTvocIndex = (tvoc, measurementUnit) => {
  if (!measurementUnit) return tvoc

  if (measurementUnit === MeasurementUnits.VOCS_UG_WELL) {
    return ugWelltoIndex(tvoc)
  }

  if (measurementUnit === MeasurementUnits.VOCS_UG_RESET) {
    return ugResetToIndex(tvoc)
  }

  if (measurementUnit === MeasurementUnits.VOCS_PPB) {
    return ppbToIndex(tvoc)
  }

  return tvoc
}

export const convertTvocIndex = (tvoc, measurementUnit) => {
  if (measurementUnit === MeasurementUnits.VOCS_INDEX) {
    return tvoc
  }

  if (measurementUnit === MeasurementUnits.VOCS_UG_WELL) {
    return indexToUgWell(tvoc)
  }

  if (measurementUnit === MeasurementUnits.VOCS_UG_RESET) {
    return indexToUgReset(tvoc)
  }

  if (measurementUnit === MeasurementUnits.VOCS_PPB) {
    return indexToPpb(tvoc)
  }
}

const ugResetToPpb = value => value / 2.3

const ugWellToPpb = value => value / 4.5

const ppbToUgReset = value => value * 2.3

const ppbToUgWell = value => value * 4.5

export const normalizeTvoc = (tvoc, measurementUnit) => {
  if (!measurementUnit) return tvoc

  if (measurementUnit === MeasurementUnits.VOCS_UG_WELL) {
    return ugWellToPpb(tvoc)
  }

  if (measurementUnit === MeasurementUnits.VOCS_UG_RESET) {
    return ugResetToPpb(tvoc)
  }

  return tvoc
}

export const convertTvoc = (tvoc, measurementUnit) => {
  if (measurementUnit === MeasurementUnits.VOCS_PPB) {
    return tvoc
  }

  if (measurementUnit === MeasurementUnits.VOCS_UG_WELL) {
    return ppbToUgWell(tvoc)
  }

  if (measurementUnit === MeasurementUnits.VOCS_UG_RESET) {
    return ppbToUgReset(tvoc)
  }
}

// ---------------------------------- FORMALDEHYDE -------------------------------- //

const ugToPpb = value => value / 1.25

const ppbToUg = value => value * 1.25

export const normalizeFormaldehyde = (formaldehyde, measurementUnit) => {
  if (!measurementUnit) return formaldehyde

  if (measurementUnit === MeasurementUnits.FORMALDEHYDE_PPB) {
    return ppbToUg(formaldehyde, measurementUnit)
  }

  return formaldehyde
}

export const convertFormaldehyde = (formaldehyde, measurementUnit) => {
  if (measurementUnit === MeasurementUnits.FORMALDEHYDE_PPB) {
    return ugToPpb(formaldehyde)
  }
  return formaldehyde
}

// ---------------------------------- O3 -------------------------------- //

const O3ppbtoUg = value => value * 1.96
const o3UgtoPpb = value => value / 1.96

export const normalizeO3 = (o3, measurementUnit) => {
  if (!measurementUnit) return o3

  if (measurementUnit === MeasurementUnits.O3_UG) {
    return o3UgtoPpb(o3)
  }
  return o3
}

export const convertO3 = (o3, measurementUnit, oldO3Units) => {
  if (measurementUnit === MeasurementUnits.O3_UG && !oldO3Units) {
    return O3ppbtoUg(o3)
  }
  if (measurementUnit === MeasurementUnits.O3_PPB && oldO3Units) {
    return o3UgtoPpb(o3)
  }
  return o3
}

// ---------------------------------- CO -------------------------------- //

const COppmtoMg = value => value * 1.15
const coMgtoPpm = value => value / 1.15

export const normalizeCO = (co, measurementUnit) => {
  if (!measurementUnit) return co

  if (measurementUnit === MeasurementUnits.CO_MG) {
    return coMgtoPpm(co)
  }
  return co
}

export const convertCO = (co, measurementUnit) => {
  if (measurementUnit === MeasurementUnits.CO_MG) {
    return COppmtoMg(co)
  }
  return co
}

// ---------------------------------- NO2 -------------------------------- //

const NO2ppbtoUg = value => value * 1.88
const NO2UgtoPpb = value => value / 1.88

export const normalizeNo2 = (no2, measurementUnit) => {
  if (!measurementUnit) return no2

  if (measurementUnit === MeasurementUnits.NO2_UG) {
    return NO2UgtoPpb(no2)
  }
  return no2
}

export const convertNO2 = (no2, measurementUnit) => {
  if (measurementUnit === MeasurementUnits.NO2_UG) {
    return NO2ppbtoUg(no2)
  }
  return no2
}

// ---------------------------------- INDICATORS -------------------------------- //
export const isIndicator = type => {
  return (
    type === DataTypes.COVID_19 ||
    type === DataTypes.IAQ ||
    type === DataTypes.VENTILATION_INDICATOR ||
    type === DataTypes.THERMAL_INDICATOR
  )
}
