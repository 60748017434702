import { useState } from 'react'
import PropTypes from 'prop-types'
import { withLocalize } from 'react-localize-redux'
import _ from 'lodash'
import FileSaver from 'file-saver'

import { Modal } from '@material-ui/core'

import { requestFile } from 'utils/request'
import DownloadWellStaticDataForm from '../components/DownloadWellStaticDataForm'
import styles from './DownloadWellStaticDataContainer.module.scss'
import { getAuthStoreData } from 'utils/authStore'

const DownloadWellStaticDataContainer = props => {
  const [open] = useState(true)
  const [submitError, setSubmitError] = useState(null)
  const { hideModal, systems } = props

  let systemsByGroup = _.groupBy(systems, 'group')
  const handleSubmit = async data => {
    if (!data) return
    if (props.isSubmitting) return

    const submitData = {
      params: data.params,
      testDate: data.testDate
    }
    const authStoreData = getAuthStoreData()
    try {
      await requestFile(`systems/` + data.deviceId + `/staticwell/`, {
        method: 'POST',
        headers: {
          accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'content-type': 'application/json',
          Authorization: authStoreData
        },
        data: {
          ...submitData
        }
      }).then(async response => {
        const blob = await response.blob().then(blob => blob)
        const fileName = response.headers.get('filename')
        FileSaver.saveAs(blob, fileName)
      })
      hideModal()
    } catch (error) {
      setSubmitError(error)
    }
  }

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <DownloadWellStaticDataForm
          {...props}
          onSubmit={handleSubmit}
          systems={systems}
          systemsByGroup={systemsByGroup}
          submitError={submitError}
        />
      </div>
    </Modal>
  )
}

DownloadWellStaticDataContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(DownloadWellStaticDataContainer)
