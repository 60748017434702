import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Modal } from '@material-ui/core'
import { connect } from 'react-redux'
import { getUser } from 'common/selectors/user'
import { hideModal } from '../../../../common/actions/modal'
import {
  updateSystemGlobalPeriodicity,
  updateSystemPeriodicity
} from '../../../../common/actions/systems'
import { getError, getIsSubmitting } from '../selectors'
import { resetError } from 'common/actions/error'
import EditSystemPeriodicityForm from '../components/EditSystemPeriodicityForm/EditSystemPeriodicityForm'
import styles from './EditSystemPeriodicityContainer.module.scss'

const EditSystemPeriodicityContainer = props => {
  const [open] = useState(true)

  const {
    hideModal,
    system,
    updateSystemGlobalPeriodicity,
    updateSystemPeriodicity,
    resetError,
    user
  } = props

  const { isPremium, collaborativeInformation } = user.toJS()

  const isManager =
    collaborativeInformation?.length > 0 && collaborativeInformation[0]?.role === 'Manager'

  const handleSubmit = async data => {
    if (!(isPremium || isManager) && Number(data.timeInterval) < 10) {
      return
    }

    if (data.selectAll) {
      updateSystemGlobalPeriodicity({
        CustomerId: user.toJS()._id,
        timeInterval: Number(data.timeInterval)
      })
    } else {
      updateSystemPeriodicity({
        _id: system._id,
        timeInterval: Number(data.timeInterval)
      })
    }
  }

  const initialValues = {
    timeInterval: system.timeInterval
  }

  useEffect(() => {
    resetError()
  }, [resetError])

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.content}>
        <EditSystemPeriodicityForm
          {...props}
          initialValues={initialValues}
          hideModal={hideModal}
          onSubmit={handleSubmit}
          isPremium={isPremium}
          isManager={isManager}
        />
      </div>
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => ({
  user: getUser(state),
  submitError: getError(state),
  isSubmitting: getIsSubmitting(state)
})

const mapActionsToProps = {
  updateSystemPeriodicity,
  updateSystemGlobalPeriodicity,
  hideModal,
  resetError
}

EditSystemPeriodicityContainer.propTypes = {
  hideModal: PropTypes.func.isRequired
}

export default connect(mapStateToProps, mapActionsToProps)(EditSystemPeriodicityContainer)
